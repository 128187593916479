import React from "react";
import Gfooter from "../Component/Gfooter";

function DataEntry() {
  return (
    <>
      <div className="container data-entry-sec data-entry-top">
        <h1 className="data-entry-heading">
          <strong>Data Entry</strong>
        </h1>
        <p className="data-para">
             For complete peace of mind, increased productivity , and improved
             profit margins, outsource your data entry tasks.
            </p>
        <div className="row data-entry-data">
        <div className="data-entry-image col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="../../service-pic/dataentry.jpg" alt="testing pic" />
          </div>
          <div className="data-entry-content col-lg-6 col-md-12 col-sm-12">
            <p className="data-entry-para" data-aos="fade-up" data-aos-delay={100}>
             Data Entry is an essential skill for any business.
             Quick and Precise Services, the convenience of back-office
             operations is ensured by using professional data entry tools and
             adhering to rigorous quality rules. This allows you to concentrate
             on your core skills and increase sales. By utilizing
             industry-standard technologies and optimal data entry techniques,
             we guarantee optimal data correctness, secure data processing and
             storage, quick turnaround times, and much more.
             For complete peace of mind, increased productivity, and improved profit margins, outsource your data entry tasks.
            </p>
          </div>
        </div>
      </div>
      <Gfooter/>
    </>
  );
}

export default DataEntry;
