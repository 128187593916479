import React from "react";
import './blog.css'
import ButtonBlog from "./ButtonBlog";
import Gfooter from "../Component/Gfooter";
import Blog5 from "./Blog5";
import Card1 from "./Card1";

function Blog() {
  return (
    <div className="blog-top">
    <nav className="navbar bg-body-tertiary">
      {/* <div className="blog-img col-lg-12 col-md-12 col-sm-12">
        <img src="about-img/blog page.jpg" alt="blog background"/>
        <div className="blog-banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Blogs</h1>
                  <p>"Empower your business with tailored solutions that drive efficiency, growth, and success."</p>
                  <div className="blog-box">
                    <span className="mb-0 box-home">Home</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Blogs</span>
                  </div>
                </div>
      </div> */}
      <div className="container box-blog">
        <h1 className="navbar-brand">Blog</h1>
        <p className="dot"></p>
        <form className="d-flex" role="search">
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search Topics"
            aria-label="Search"
          />
          <i className="fa-solid fa-magnifying-glass search-icon" />
        </form>
      </div>
    </nav>
    {/* <Blog5/> */}
    <Card1/>
      <ButtonBlog/>
<Gfooter/>
    </div>
  );
}

export default Blog;
