import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function ClientSpeackData() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, 
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0', 
    autoplay: true,
    autoplaySpeed: 1500,
    focusOnSelect: true,
    responsive:[
      {
        breakpoint:468,
        settings:{
          slidesToShow:1,
        }
      }
    ]
  };

  const testimonials = [
    {
      content: "The Global iFile Management System has been a game-changer for our business. We have cut down on manual data entry by over the 70%, and the AI-powered search allows to our team to find critical documents instantly. It is streamlined our workflow and significantly improved our productivity !!",
      // name: "NOBITA",
      subheading: "Customer",
      img: "/img/profile1.png"
    },
    {
      content: "As our company grew, organizing documents became a nightmare. until we implemented the Global iFile DMS. The AI features not only keep everything organized but also make collaboration easy across departments. Our document handling has never been this efficient! - please give any client name from the client list and image as well.",
      // name: "SHIZUKA",
      subheading: "Customer",
      img: "/img/profile2.jpg"
    },
    // {
    //   content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    //   name: "GIAN",
    //   subheading: "Customer",
    //   img: "/img/profile3.png"
    // },
    // {
    //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    //   name: "SUNEO",
    //   subheading: "Customer",
    //   img: "/img/profile4.jpg"
    // },
    // {
    //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    //   name: "DEKISUGI",
    //   subheading: "Customer",
    //   img: "/img/profile5.png"
    // },
    // {
    //   content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    //   name: "Michael Brown",
    //   subheading: "Customer",
    //   img: "/img/profile6.png"
    // },
  ];

  return (
    <div className="client-speack-top">
       <div className="container">
        <h1 className="heading-about" data-aos="fade-up" data-aos-delay={20}>
          <strong>Client Speak</strong>
        </h1>
      </div>
      <div className="clients-speak-section col-lg-12 col-md-12 col-sm-12">
        <div className="right-section col-lg-12 col-md-12 col-sm-12">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial">
                <p>{testimonial.content}</p>
                <div className="client-info">
                  <img src={testimonial.img} alt={testimonial.name} />
                  <div>
                    <h4>{testimonial.name}</h4>
                    <p>{testimonial.subheading}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ClientSpeackData;
