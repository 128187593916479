import React from "react";
import Gfooter from "../Component/Gfooter";
function MobileSolutions() {
  return (
    <>
      <div className="container mobile-sol-sec mob-sol-sec2">
        <h1 className="mobile-solution-heading">
          <strong>Mobile Solution</strong>
        </h1>

        <div className="row mobile-solution-data for-common-effect">
        <div className="mobile-solution-image col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="../../service-pic/mobilesolution.jpg" alt="Mobile Solution" />
          </div>
          <div className="mobile-solution-content-sec col-lg-6 col-md-12 col-sm-12">
            <p className="test-para" data-aos="fade-up" data-aos-delay={100}>
               Developing and deploying effective mobility solutions using
               innovative web, mobile and cloud technologies. Enterprises today
               are chasing the mobile gold rush without completely understanding
               the subtle differences between mobile and mobility. While mobile
               includes making everything compatible for a device, mobility on
               the other hand means optimizing the lives of your customers. It is
               essential that businesses understand where their customers are,
               what their culture is, and what their goals are, etc. These
               insights become the fundamentals of a good mobility solution.
            </p>
          </div>
         
        </div>
      </div>
      <Gfooter/>
    </>
  );
}

export default MobileSolutions;

