import React from "react";
import { Link } from "react-router-dom";
import Gfooter from "../Component/Gfooter";

function Project() {
  return (
    <>
      <div className="container-fluid project-bg ">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <img src="career-img/projectbg.webp" alt="  Qlikview Developer" />
        </div>
      </div>
      <div className="container-fluid job-details">
        <div className="row">
          <div className="col-lg-12 col-md-10 col-sm-12">
              <h1 className="job-project-heading"><strong>Qlikview Developer</strong></h1>
          </div>
        </div>

        <div className="max-w-1200 mx-auto">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-7 col-sm-12 pr-50 mb-5">
              <div className="job-description-container ql-editor">
                <h2>Requirements :</h2>
                <p>
                  <span>
                    Preferably candidate having 3-5 years of design and
                    development experience using QlikView/Qlik Sense tool (Qlik
                    Data Modeling, Set analysis, AGGR function, Alternate State,
                    Drill Down, Cyclic Group, Incremental Load with
                    update/Delete). Strong SQL experience (Type of Joins,
                    Analytical function [like RowNumber, Rank, Denserank],
                    Subqueries/correlated subqueries). Strong Data Warehousing
                    experience with ETL knowledge (Star/Snowflake schema, Types
                    of Dimension/Fact, Slowly Changing Dimension)
                    <p className="set-data">
                      <ul>
                        <li>
                          <b>*</b> Multi-tierQLIK Solution Architecture.
                        </li>
                        <li>
                          <b>*</b> Must have experience intoClient Communication
                          and Requirement gathering.
                        </li>
                        <li>
                          <b>*</b> Must becomfortable working as Individual
                          Contributor.{" "}
                        </li>

                        <p className="good">Good to have :</p>
                        <li>
                          <b>*</b> QlikView/Qlik Sense Server cluster
                          installation/knowledge.
                        </li>
                        <li>
                          <b>*</b> Design and develop QlikView/Qlik Sense
                          mobileapplications.{" "}
                        </li>
                        <li>
                          <b>*</b> Qlik Sense extension experience, Advanced
                          Reporting capabilities with Acquisition of NPrinting.
                        </li>
                        <li>
                          <b>*</b> QMCadmin activities (Task creation,
                          Scheduling job, Loop and reduce, License managing
                          etc).
                        </li>
                        <li>
                          <b>*</b> Client management skills.
                        </li>
                      </ul>
                    </p>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="sticky-job">
                <div className="btn-container col-md-9 col-sm-12">
                  <button className="btn btn-info" id="apply-job">
                    <Link to="/form">
                      Apply for this job{" "}
                      <span className="arrow-icon">
                        <i className="fa-solid fa-arrow-right-long" />
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<Gfooter/>
    </>
  );
}

export default Project;
