import React, { useState } from 'react';

const Details = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    jobTitle: '',
    currentlyWorking: false,
    dateOfJoining: '',
    dateOfRelieving: '',
    location: '',
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can handle the form submission logic
    console.log('Form submitted with data:', formData);
    // Reset form after submission
    setFormData({
      companyName: '',
      jobTitle: '',
      currentlyWorking: false,
      dateOfJoining: '',
      dateOfRelieving: '',
      location: '',
    });
    setShowForm(false); // Hide the form after submission
  };

  const handleButtonClick = () => {
    setShowForm(true); // Set showForm state to true to display the form
  };

  return (
    <div className='container  detail-top mt-5'>
      <button className="detail-button1" onClick={handleButtonClick}>+ Add Experience Details</button>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <div className='row mt-3'>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <small>Company Name*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <small>Job Title*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Job title"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleInputChange}
                required
              />
            </div>
            {/* <div className='col-lg-2 col-md-4 col-sm-12'> */}
              {/* <small>Currently working here</small>
              <input
                className=" checkbox form-check-input"
                id="currentlyWorking"
                 type="checkbox"
                name="currentlyWorking"
                  
                checked={formData.currentlyWorking}
                onChange={handleInputChange}
              /> */}
                <div classname="form-check">
               <input classname="form-check-input" type="checkbox"
                defaultValue id="flexCheckDefault" />        
               <label className="form-check-label"
                htmlFor="flexCheckDefault">
            <small> {" "}Currently working here</small>
         </label>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <small>Date of Joining*</small>
              <input
                type="date"
                className="form-control"
                placeholder="dd/mm/yy"
                name="dateOfJoining"
                value={formData.dateOfJoining}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <small>Date of Relieving*</small>
              <input
                type="date"
                className="form-control"
                placeholder="Date of Relieving"
                name="dateOfRelieving"
                value={formData.dateOfRelieving}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <small>Location*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Location"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
         
        </form>
      )}
              <br/><br/>
      <div className="g-recaptcha" data-sitekey="6Le2RgkqAAAAADsLwTeH9pZuv3obNwLoFrOQs1Fb">
      </div>  
      <div classname="form-check">
            <input classname="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" /> {" "}     
               <label className="form-check-label" htmlFor="flexCheckDefault">
           <small>   By applying, you hereby accept the data processing terms under the  <a href="privacy policy">Privacy Policy </a> and give consent to processing of the data as part of this job application.</small>
        </label><br/>
        </div>
        <button type="text"  className="apply-btn btn-secondary mt-2" >Apply Now</button>
    </div>
  );
};

export default Details;