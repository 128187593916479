import React from "react";
import Gfooter from "./Gfooter";

function Privacy() {
  return (
    <>
    <div className="privacy-top">
      <div className=" box27 container">
        <h3 className="box25">PRIVACY POLICY</h3>
        <p>
          Global Suntec Solutions Pvt Ltd, we are dedicated to safeguarding the
          privacy and security of your personal information. This Privacy Policy
          elucidates how we gather, utilize, divulge, and safeguard the personal
          data we obtain from users of our software products and services.
        </p>
        <h6>1.INFORMATION WE COLLECT</h6>
        <p>
          We may collect various types of personal information directly from you
          or automatically through your use of our software products or
          services. This information may include:
        </p>
        
        <p>
          <ul>
          <li>Account Information:</li> Contact details (such as name, email
          address, phone number) and account credentials (such as username,
          password).
          <li>Payment Information:</li> Information required to process
          transactions and payments, such as credit card details or other
          payment methods.
          <li>Usage Data: </li>Information about how you interact with our
          products and services, including log data, device information (such as
          IP address, browser type, operating system), and usage patterns.
          <li>Communication Data:</li> Information you provide when
          communicating with us, such as customer support inquiries or feedback.
          </ul>
        </p>
        <h6>2.USE OF INFORMATION</h6>
        <p>
          We employ the personal data we collect for various purposes,including
        </p>
        <p>
          <ul>
          <li>Product and Service Provision:</li> To provide and maintain our
          software products and services, fulfill orders, process payments, and
          deliver customer support.
          <li>Personalization:</li> To personalize your experience with our
          products and services, including content recommendations, tailored
          advertisements, and product suggestions.
          <li>Analytics and Improvement:</li> To analyze usage trends, monitor
          the effectiveness of our marketing campaigns, and improve the quality
          and features of our products and services.
          <li>Communication: </li>To communicate with you about updates,
          promotions, events, and other relevant information regarding our
          products and services.
          </ul>
        </p>

        <h6>3.SHARING OF INFORMATION</h6>
        <p>
          We may share personal information with third parties in the following
          circumstances:
        </p>
            
        <p>
          <ul>
            <li>Service Providers:</li>
           With trusted third-party service providers who
          assist us in delivering our products and services, such as payment
          processors, hosting providers, and analytics services. 
          <li>Business Partners:</li> With our business partners for joint marketing efforts,
          collaborations, or other mutually beneficial activities. 
          <li>Legal Compliance and Protection:</li> To comply with legal obligations, enforce
          our policies, protect our rights or property, or respond to legal
          requests from law enforcement or government authorities.
          </ul>
        </p>

        <h6>4.DATA SECURITY</h6>
        <p>
          We implement appropriate technical and organizational measures to
          protect the personal data we collect against unauthorized access,
          disclosure, alteration, or destruction. However, no method of
          transmission over the internet or electronic storage is entirely
          secure, and we cannot guarantee absolute security.
        </p>

        <h6>5.YOUR RIGHTS</h6>
        <p>
          You have certain rights regarding your personal information,
          including:
        </p>
        <p>
          <ul>
          <li>Access and Correction: </li>The right to access, update, or correct your
          personal information. 
          <li>Data Portability: </li>The right to request a copy of
          your personal data in a structured, machine-readable format. 
          <li>Erasure:</li>
          The right to request the deletion of your personal information,
          subject to certain legal exceptions. 
          <li>Objection: </li>The right to object to the processing of your personal data in certain circumstances, such as
          direct marketing. 
          <li>Withdrawal of Consent: </li>The right to withdraw consent
          for the collection and processing of your personal data at any time,
          where applicable.
          </ul>
        </p>

        <h6>6. COOKIES AND SIMILAR TECHNOLOGIES</h6>

        <p>
          We may use cookies, web beacons, and similar technologies to collect
          information about your interactions with our website and services. You
          can manage your cookie preferences through your browser settings or
          opt-out of certain tracking technologies.
        </p>

        <h6>7. CHILDREN'S PRIVACY</h6>

        <p>
          Our products and services are not directed at children under the age
          of 13, and we do not knowingly collect personal information from
          children under the age of 13 without verifiable parental consent. If
          we become aware that we have inadvertently collected personal
          information from a child under 13, we will take steps to delete such
          information promptly.
        </p>

        <h6>8.CHANGES TO THIS PRIVACY POLICY</h6>

        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. We will notify you of any material changes by posting the
          revised Privacy Policy on our website or through other means of
          communication.
        </p>

        <h6>9.CONTACT US</h6>

        <p>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our privacy practices, please contact us at
          info@gsuntec.com.
        </p>
      </div>
    </div>
<Gfooter/>
    </>
  );
}

export default Privacy;
