import React from "react";
import { Link } from "react-router-dom";
function Newform() {
  return (
    <section className="contact-section">
      <div
        className="container-fluid contact-top"
        data-aos="fade-down"
        data-aos-delay={40}
      >
        <h1>Have A Project Or Partnership In Mind?</h1>
        <h4>Get in Touch</h4>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div
              className="contact_content"
              data-aos="fade-right"
              data-aos-delay={100}
            >
              <div className="contact-box">
                <div className="box-image">
                  <figure className="contact-location">
                    <img
                      src="img/contact-location.png"
                      alt="location"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="box-content">
                  <h4>Location:</h4>
                  <p className="text">
                  Global Suntec Solutions Pvt. Ltd E6 Laxmipuram, Infront Of Commerce Zone IT Park Pune 411006 MH, INDIA 
                  </p>
                </div>
              </div>
              <div className="contact-box">
                <div className="box-image">
                  <figure className="contact-email">
                    <img
                      src="img/contact-email.png"
                      alt="email"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="box-content">
                  <h4 className="heading">Email:</h4>
                  <p>
                    <a
                      href="vaibhav@gsuntec.com"
                      className="text-decoration-none"
                    >
                      vaibhav@gsuntec.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="contact-box box-mb">
                <div className="box-image">
                  <figure className="contact-phone">
                    <img
                      src="img/contact-phone.png"
                      alt="phone"
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="box-content">
                  <h4 className="heading">Phone:</h4>
                  <p>
                    <a
                      href="tel:+91-95118 77603"
                      className="text-decoration-none text"
                    >
                      +91-95118 77603
                    </a>
                  </p>
                  <p>
                    <a
                      href="tel:++91-9028315486"
                      className="mb-0 text-decoration-none text"
                    >
                      +91-9028315486
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-7 col-md-12 col-sm-12 col-12"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="need-section">
              <div className="need_content">
                <h6 className="text-white">Write to us</h6>
                <h2 className="text-white">Send us a Message</h2>
                <form id="contactpage">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <input
                          type=" text"
                          className="box300 form_style"
                          placeholder="Full Name"
                          name="name"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className=" box300 form_style"
                          placeholder="Work Email"
                          name="emailid"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group mb-0">
                        <input
                          type="email"
                          className=" box300 form_style"
                          placeholder="Company Name"
                          name="company name"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="input-group">
                        <select
                          id="countryDropdown"
                          className=" India (+91)form-control country-select"
                        >
                          <option value="+91">IND (+91)</option>
                          <option value="+65">SGP (+65)</option>
                          <option value="+43">AUT (+43)</option>
                        </select>
                        <input
                          type="number"
                          className="form-control country-select"
                          placeholder="Mobile Number"
                          name="mobilePhone"
                          maxlength="15"
                          pattern="\d{15}"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div classname="check">
                        <input type="checkbox" />
                        <span>
                          {" "}
                          {""}I agree to the{" "}
                          <Link to="/privacy" className="privacy">
                            privacy policy
                          </Link>{" "}
                        </span>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="col-lg-12">
                      <div className=" form-group mb-0">
                        <textarea
                          className=" box302 form_style"
                          placeholder="Message"
                          rows={3}
                          name="msg"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="manage-button text-center">
                    <button
                      type="submit"
                      className="box301 text-white text-decoration-none"
                    >
                      Submit
                      {/* <i className="circle fa-thin fa-arrow-right" /> */}
                    </button>
                  </div>
                </form>
                {/* <figure className="faq-image mb-0">
                    <img src="faq-image.png" alt className="img-fluid" />
                  </figure> */}
              </div>
            </div>
          </div>
          <div className="box17 row">
            <div className="col-lg-3 col-md-6  col-sm-12 contact-add">
              <h5>PUNE</h5>
              <span>
                Global Suntec Solutions Pvt. Ltd
                <br />
                E6 Laxmipuram, Infront Of Commerce Zone IT Park
                <br />
                Pune 411006 MH, INDIA
                <br />
                +91-9028315486
              </span>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 contact-add">
              <h5>NAGPUR</h5>
              <span>
                Global Suntec Solutions Pvt. Ltd.
                <br />
                31 Madhuban,
                <br />
                Harpur Nagar,
                <br />
                Nagpur 440009 MH, INDIA
                <br />
                +91-95118 77603
              </span>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 contact-add">
              <h5>SINGAPORE</h5>
              <span>
                Global Suntec Solutions Pvt. Ltd.
                <br />
                #16-681,22 Havelock road, <br />
                Tiong Bahru
                <br />
                Singapore 160022
                <br />
                +65-86229070
              </span>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 contact-add">
              <h5>AUSTRIA</h5>
              <span>
                Global Suntec Solutions Pvt. Ltd.
                <br />
                Holzmeistergasse 13/2/18 , <br />
                1210 Vienna , Austria <br />
                +43-68864043755
              </span>
            </div>
          </div>
        </div>
      </div>
      <figure className="layer-bottom offer-bottomlayer mb-0">
        {/* <img src="offer-bottomlayer.png" alt className="img-fluid" /> */}
      </figure>
    </section>
  );
}

export default Newform;
