import React from "react";
import Gfooter from "../Component/Gfooter";
function Esolution() {
  return (
    <>
      <div className="container interprice-solution-sec Esolution-top">
        <h1 className="interprice-solution-heading">
          <strong>Enterprice Solution</strong>
        </h1>
        <div className="row interprice-solution-data for-common-effect">
          <div className="interprice-solution-image col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="../../service-pic/enterprise.jpg" alt="Enterprice Solution" />
          </div>
          <div className="interprice-solution-sec col-lg-6 col-md-12 col-sm-12">
            <p className="interprice-solution-para" data-aos="fade-up" data-aos-delay={100}>
              Let us help you to build deeper analytical capabilities into your
              trade and embrace the future and restructure your business with
              Enterprise Business and Mobility Solutions offered by us.
              Similarly with our Enterprise Mobility Solutions we mobilize your
              organisation and help you to keep your employees productive when
              they are on move with their favourite apps and devices while
              keeping your corporate data protected. With our strategic
              partnership with ORACLE, SAP, MICROSOFT and others we make a
              positive difference to our customers by aligning their business
              with the latest Information Technology and enhance value from
              their investments.
            </p>
          </div>
          </div>
      </div>
      <Gfooter/>
    </>
  );
}

export default Esolution;

