import React from 'react'
import './contact.css'
import Gfooter from '../Component/Gfooter';
import Newform from './Newform';

function Contact() {
    return ( 
        <>
         {/* <div className="contact-top col-lg-12 col-md-12 col-sm-12">
         <img src="img/Contact Page.jpg" alt="case study banner"/>
         <div className="contact-banner-content aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Contact Us</h1>
                  <p>Doidunt eget semper nec ruam sed hendrerit morbi ac feliseao augue pellentesue morbi acer.</p>
                  <div className="contact-box">
                    <span className="mb-0 box-home">Home</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Contact Us</span>
                  </div>
                </div>
         </div> */}
        {/* <Form/> */}
      <Newform/>
        {/* <Address/> */}
<Gfooter/>
        </>
     );
}

export default Contact;