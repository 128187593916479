import React, { useState, useEffect } from 'react';
import VideoLoader from './VideoLoader'; // Adjust the path if needed

function Video() {
  const [loading, setLoading] = useState(true);

  // Simulate an async operation like fetching data
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
  }, 3000); 

      return () => clearTimeout(timer);
  }, []);

    return (
        <div className='video-content'>
            {loading ? (
                <VideoLoader />
            ) : (
                <div>
                </div>
            )}
        </div>
    );
}

export default Video;
