import React from "react";
import { Link } from "react-router-dom";

function Form() {
  return (
    <div className="form-top">
      <div className=" box15 container big-container ">
        <section className="banner gradient-blue contact-banner contact-pb-20">
          <div className="row align-items-start text-center justify-content-center">
            <div className="col-lg-9 form1">
              <h1>
                Have A Project Or
                <br /> Partnership In Mind?
              </h1>
              <h4 className="mb-1">
                We’d love to hear from you. Please share more information and we
                will get back to you within one working day.
                <br />
              </h4>
              <br />
              <br />

              <div className="box18 container ">
                <form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                      <small>Full Name</small>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Full Name"
                        name="full name"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                      <small>Company Name</small>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        name="pswd"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <small>Work Email</small>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Work Email"
                        name="email"
                      />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 mt-4">
                      <small>Country</small>
                      <select
                        className="form-select form-select-sm"
                        aria-label="Small select example"
                      >
                        <option selected>
                          <small>INDIA</small>
                        </option>
                        <option value="2">AUSTRIA</option>
                        <option value="3">SINGAPORE</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
                      <small>Phone Number</small>
                      <input
                        type="number"
                        className="no-spinner form-control"
                        placeholder="number"
                        // name="pswd"
                      />
                    </div>
                    <div className="row message-box">
                      <div className="col-lg-12 col-md-12 col-sm-12  ">
                        <small>How can we help you</small>
                        <textarea
                          className="form-control "
                          id="message"
                          rows={2}
                          placeholder="write your message"
                          defaultValue={""}
                        />
                        <br />
                        <div classname="form-check">
                          <input
                            classname="form-check-input"
                            type="checkbox"
                            defaultValue
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            <small>
                              {" "}
                              I agree to the{" "}
                              <Link to="/privacy">privacy policy</Link>
                            </small>
                          </label>
                            {/* <br/>
                            <small>
                              Looking for a Job? 
                              <Link to = "/ApplyHere">Apply here</Link>
                            </small> */}
                          <br />
                        </div>
                        <div
                          className="g-recaptcha"
                          data-sitekey="6Le2RgkqAAAAADsLwTeH9pZuv3obNwLoFrOQs1Fb"
                        ></div>
                        <button type="Contact Us" className="btn btn-secondary">
                          <small>Contact Us</small>
                        </button>{" "}
                        <br />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
     
  );
}

export default Form;
