import React from 'react'
import ProductCard from './ProductCard';
import Product from './Product';
import Gfooter from '../Component/Gfooter';

function NewProduct() {
    return ( 
        <>
        <ProductCard/>
        <Product/>
        <Gfooter/>
        </>
     );
}

export default NewProduct;