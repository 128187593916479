import React from "react";
import { useEffect, useState } from "react";

function Counter({ end, duration }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const incrementTime = duration / end;
    const counter = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === end) {
        clearInterval(counter);
      }
    }, incrementTime * 1000);

    return () => clearInterval(counter);
  }, [end, duration]);

  return <span>{count}</span>;
}

// function OurNumber() {
//   return (
//     <div className="ournumber-top">
//       <div
//         className="counter-area1"
//         style={{ backgroundImage: 'url("img/section5.jpg")' }}
//       >
//         <div className="container-fluid number-head">
//           <div className="counter-title">
//             <h2>We Take Pride in Our Numbers</h2>
//           </div>
//           <div className="counter-content">
//             <div
//               className="col-lg-4 col-md-4 col-sm-4 col-xs-12 counter1-box wow fadeInDown"
//               data-wow-duration=".5s"
//               data-wow-delay=".20s"
//             >
// <span className="counter">
//   <Counter end={28} duration={2} />
// </span>
//               <span>+</span>
//               <p>Years Of Excellence</p>
//             </div>
//             <div
//               className="col-lg-4 col-md-4 col-sm-4 col-xs-12 counter1-box wow fadeInDown"
//               data-wow-duration=".5s"
//               data-wow-delay=".40s"
//             >
// <span className="counter">
//   <Counter end={450} duration={0.5} />
// </span>
//               <span>+</span>
//               <p>Clients</p>
//             </div>
//             <div
//               className="col-lg-4 col-md-4 col-sm-4 col-xs-12 counter1-box wow fadeInDown"
//               data-wow-duration=".5s"
//               data-wow-delay=".60s"
//             >
//               <span className="counter">
//                 <Counter end={25} duration={2} />
//               </span>
//               <span>+</span>
//               <p>Brands</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OurNumber;

function OurNumber() {
  return (
    <section className="achievement-section">
      <div className="container ">
        <div className="row">
          <figure className="toplayer mb-0">
          <img
            src="img/offer-toplayer.png"
            alt=""
            className="img-fluid"
          />
        </figure>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div
              className="achievement_content aos-init aos-animate"
              data-aos="fade-right"
            >
              <h2><strong>We Take Pride in Our Numbers</strong></h2>
              <p> They represent the collective effort of our dedicated team and the trust of our valued clients.</p>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="achievement_wrapper">
              <div className="achievement-box box1">
                <figure className="icon achievement-icon1">
                  <img src="img/client.png" alt="client" className="img-fluid" />
                </figure>
                <div className="wrapper pride-data">
                  <div className="number number1">
                    <span className="counter">
                      <Counter end={4} duration={2.8} />
                    </span>
                    <span className="plus">+</span>
                  </div>
                  <p className="text text-size-18 mb-0">Years Of Excellence</p>
                </div>
              </div>
              <div className="achievement-box box2">
                <figure className="icon achievement-icon">
                  <img src="img/years.png" alt="years" className="img-fluid" />
                </figure>
                <div className="wrapper">
                  <div className="number number1">
                    <span className="counter">
                      <Counter end={45} duration={1.5} />
                    </span>
                    <span className="plus">+</span>
                  </div>
                  <p className="text text-size-18 mb-0">Clients</p>
                </div>
              </div>
              <div className="achievement-box box3">
                <figure className="icon achievement-icon">
                  <img src="img/brand.png" alt="brand" className="img-fluid" />
                </figure>
                <div className="wrapper">
                  <div className="number number1">
                    <span className="counter">
                      <Counter end={25} duration={2.8} />
                    </span>
                    <span className="plus">+</span>
                  </div>
                  <p className="text text-size-18 mb-0">Brands</p>
                </div>
              </div>
              {/* <div className="achievement-box box4">
      
                <div className="wrapper">
                  <div className="number">
                    <h3 className="value counter">180</h3>
                    <span className="plus">+</span>
                  </div>
                  <p className="text text-size-18 mb-0">Awards Win</p>
                </div>
              </div> */}
            </div>
          </div>
          <figure className="bottomlayer mb-0">
          <img
            src="img/offer-bottomlayer.png"
            alt=""
            className="img-fluid"
          />
        </figure>
        </div>
      </div>
    </section>
  );
}

export default OurNumber;
