import React from "react";
import "./AboutNewRead.css";
function AboutNewRead() {
  return (
    <>
      <section className="about-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-2">
              <div className="about_wrapper">
                <figure className="mb-0 about-image1">
                  <img src="about-img/about-image1.jpg" alt="" />
                </figure>
                <figure className="mb-0 about-image2">
                  <img
                    src="about-img/about-image2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </figure>

                <figure className="mb-0 about-dot">
                  <img
                    src="about-img/about-dot.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <figure className="mb-0 about-leftcircle">
                  <img
                    src="about-img/about-leftcircle.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-1">
              <div className="about_content aos-init" data-aos="fade-right">
                <h3>About us</h3>

                <h2 className="text-white">
                  {" "}
                  We help clients invent their future.
                </h2>
                <p className="text1">
                  We Are The Fast Growing Consulting And Outsourcing Software
                  Company. We Have A Team Of Innovative Experienced
                  Professionals Who Think And Act As An Extension To Our
                  Customer’s Development Teams. Our Prime Goal Is To Meet Our
                  Customer's Expectations By Delivering Smart And Reliable
                  Software Applications To Help Them Grow Their Business.
                </p>

                <p className="text1">
                  To Do This, We Keep Track Of The Latest Innovations And Trends
                  In Software Development. We Are Continuously Incorporating The
                  Most Efficient Platforms, Programming Languages And Frameworks
                  And We Sharpen Our Technical Skills In Every Project We Work
                  On. Business Challenges – Streamline And Automate Your
                  Business Processes And Overcome Challenges Through Methodology
                  Technology.
                </p>

                <p className="text2">
                  It Is Our Goal And Driving Ambition To Be The Trusted Partner
                  Of Choice, Delivering Reliable, Complete Floating Production
                  Solutions That Create Value For Our Clients, By Sustainably
                  And Passionately Leveraging The Company’S Technology And
                  Operating Experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <figure className="mb-0 about-rightcircle">
          <img
            src="about-img/about-rightcircle.png"
            alt=""
            className="img-fluid"
          />
        </figure> */}
      </section>
    </>
  );
}

export default AboutNewRead;
