import React from "react";
import "./ProductCard.css";

function ProductCard() {
  return (
    <>
      <section className="product-section">
        <figure className="offer-toplayer mb-0">
          <img
            src="about-img/offer-toplayer.png"
            alt="top-img"
            className="img-fluid"
          />
        </figure>
        <div className="container">
          <div className="row position-relative">
            <figure className="offer-circleimage mb-0">
              <img
                src="about-img/offer-circleimage.png"
                alt="icon"
                className="img-fluid"
                class="lazyload"
              />
            </figure>
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12 article_padding  card-sec"
              data-aos="fade-right"
              data-aos-delay={150}
            >
              <div className="offer_boxcontent">
                <div className="upper_portion">
                  <div className="article_img">
                    <figure className="mb-0">
                      <img
                        src="product-img/portcard.jpg"
                        class="lazyload"
                        alt="port-img"
                      />
                    </figure>
                  </div>
                </div>
                <div className="lower_portion_wrapper">
                  <div className="lower_portion">
                    <h4 className="mb-0">Global Port Management System</h4>
                  </div>
                  <a
                    href="#product-info-section1"
                    className="read_more text-white text-decoration-none"
                  >
                    Read More
                    <figure className="mb-0">
                      <img
                        src="about-img/button-arrow.png"
                        alt="right button arraow"
                        className="arrow"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12 article_padding  card-sec"
              data-aos="fade-down"
              data-aos-delay={240}
            >
              <div className="offer_boxcontent">
                <div className="upper_portion">
                  <div className="article_img">
                    <figure className="mb-0">
                      <img
                        src="product-img/product2.jpg"
                        class="lazyload"
                        alt="parking-img"
                      />
                    </figure>
                  </div>
                </div>
                <div className="lower_portion_wrapper">
                  <div className="lower_portion">
                    <h4 className="mb-0">Global iParking Management System</h4>
                  </div>
                  <a
                    className="read_more text-white text-decoration-none"
                    href="#product-info-section2"
                  >
                    Read More
                    <figure className="mb-0">
                      <img
                        src="about-img/button-arrow.png"
                        alt="right button arrow"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12 article_padding  card-sec"
              data-aos="fade-up"
              data-aos-delay={360}
            >
              <div className="offer_boxcontent mb-0">
                <div className="upper_portion">
                  <div className="article_img">
                    <figure className="mb-0">
                      <img
                        src="product-img/ifilemanagement.jpg"
                        class="lazyload"
                        alt="ifile-img"
                      />
                    </figure>
                  </div>
                </div>
                <div className="lower_portion_wrapper">
                  <div className="lower_portion">
                    <h4 className="mb-0"> Global Ifile Management System</h4>
                  </div>
                  <a
                    className="read_more text-white text-decoration-none"
                    href="#product-info-section3"
                  >
                    Read More
                    <figure className="mb-0">
                      <img
                        src="about-img/button-arrow.png"
                        alt="right button arrow"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12 article_padding  card-sec"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="offer_boxcontent mb-0">
                <div className="upper_portion">
                  <div className="article_img">
                    <figure className="mb-0">
                      <img
                        src="product-img/product4.jpg"
                        class="lazyload"
                        alt="toll-img"
                      />
                    </figure>
                  </div>
                </div>
                <div className="lower_portion_wrapper">
                  <div className="lower_portion">
                    <h4 className="mb-0">
                      Global Toll Plaza Management System
                    </h4>
                  </div>
                  <a
                    className="read_more text-white text-decoration-none"
                    href="#product-info-section4"
                  >
                    Read More
                    <figure className="mb-0">
                      <img
                        src="about-img/button-arrow.png"
                        alt="right button arrow"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <figure className="offer-bottomlayer mb-0">
    <img
      src="about-img/offer-bottomlayer.png"
      alt=""
      className="img-fluid"
    />
  </figure> */}
      </section>
    </>
  );
}

export default ProductCard;
