import 'animate.css/animate.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './Component/Navbar';
import About from './About/About';
import CaseStudy from './casestudy/CaseStudy';
import Product from './product/Product';
import ProductReadMore from './product/ProductReadMore';
import Home from './Component/Home';
import Careers from './career/Careers';
import Blog from './Blogs/Blog';
import Contact from './Contact/Contact';
import Form from './Contact/Form';
import Privacy from './Component/Privacy';
import Tokenization from './Blogs/Tokenization';
import MobileService from './service-dropdown/MobileService';
import IfileSolution from './casestudy/IfileSolution';
import PlazaSolution from './casestudy/PlazaSolution';
import Iparking from './casestudy/Iparking';
import Project from './career/Project';
import Form3 from './career/Form3';
import TestingService from './service-dropdown/TestingService';
import EsolutionService from './service-dropdown/EsolutionService';
import BussinessService from './service-dropdown/BussinessService';
import DatascienceService from './service-dropdown/DatascienceService';
import React, { useEffect } from 'react';
import OpenPos from './career/OpenPos';
import Project1 from './career/Project1';
import Project2 from './career/Project2';
import ScrollToTop from './Component/ScrollToTop';
import DataEntryService from './service-dropdown/DataEntryService';
import IfileNew from './casestudy/IfileNew';
import Blog1 from './Blogs/Blog1';
import Blog2 from './Blogs/Blog2';
import Blog3 from './Blogs/Blog3';
import NewProduct from './product/NewProduct';
import Service from './Service-New/Service';





AOS.init();

function App() {
  useEffect(() => {
    if (document.querySelector(".custom-cursor")) {
      const cursor = document.querySelector(".custom-cursor__cursor");
      const cursorinner = document.querySelector(".custom-cursor__cursor-two");
      const links = document.querySelectorAll("a");

      const handleMouseMove = (e) => {
        cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
        cursorinner.style.left = `${e.clientX}px`;
        cursorinner.style.top = `${e.clientY}px`;
      };

      const handleMouseDown = () => {
        cursor.classList.add("click");
        cursorinner.classList.add("custom-cursor__innerhover");
      };

      const handleMouseUp = () => {
        cursor.classList.remove("click");
        cursorinner.classList.remove("custom-cursor__innerhover");
      };

      const handleLinkMouseOver = () => {
        cursor.classList.add("custom-cursor__hover");
      };

      const handleLinkMouseLeave = () => {
        cursor.classList.remove("custom-cursor__hover");
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);

      links.forEach((link) => {
        link.addEventListener("mouseover", handleLinkMouseOver);
        link.addEventListener("mouseleave", handleLinkMouseLeave);
      });

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);

        links.forEach((link) => {
          link.removeEventListener("mouseover", handleLinkMouseOver);
          link.removeEventListener("mouseleave", handleLinkMouseLeave);
        });
      };
    }
  }, []);

  
  return (
  <>
  <div className='custom-cursor'>
        <div className='custom-cursor__cursor'></div>
        <div className='custom-cursor__cursor-two'></div>
      </div>
      
   <Router>
   <div>
      
  <Navbar/>
<ScrollToTop/>
   <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route  path="/about" element={<About/>} />
        <Route  path="/services" element={<Service/>} />
        <Route  path="/services/mobile-solutions" element={<MobileService/>} />
        <Route  path="/services/testing" element={<TestingService/>} />
        <Route  path="/services/enterprise-solutions" element={<EsolutionService/>} />
        <Route  path="/services/business-intelligence" element={<BussinessService/>} />
        <Route  path="/services/data-science" element={<DatascienceService/>} />
        <Route  path="/services/data-entry" element={<DataEntryService/>} />
        <Route  path="/products" element={<NewProduct/>} />
        <Route  path="case-study" element={<CaseStudy/>} />
        <Route  path="/career" element={<Careers/>} />
        <Route  path="/blogs" element={<Blog/>} />
        <Route  path="/contact-us" element={<Contact/>} />

        <Route  path="/contact" element={<Form/>} />
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/tokan" element={<Tokenization/>}/>
        <Route path ="/read" element={<ProductReadMore/>}/>
        <Route path = "/product-page" element = {<NewProduct/>}/>
        <Route path = "/adout-page" element = {<About/>}/>
        <Route path = "/back" element = {<Careers/>}/>

        <Route path = "/Ifile" element={<IfileNew/>}/>
        <Route path = "/plaza" element={<PlazaSolution/>}/>
        <Route path = "/parking" element={<Iparking/>}/>

        <Route path = "/project1" element={<Project/>}/>
        <Route path = "/project2" element={<Project1/>}/>
        <Route path = "/project3" element={<Project2/>}/>
        <Route path = "/form" element={<Form3/>}/>
        <Route path = "/ApplyHere" element={<Form3/>}/>
        <Route path = "/explore-job" element = {<OpenPos/>}/>

        <Route path = "/blog1" element = {<Blog1/>}/>
        <Route path = "/blog2" element = {<Blog2/>}/>
        <Route path = "/blog3" element = {<Blog3/>}/>

        <Route path="*" element={<Navigate to="/" />} />

   </Routes>
</div>
   </Router>


  </>
  );
}

export default App;





