import React from "react";
import './AboutNew.css';
import {Link} from 'react-router-dom';
function AboutNew() {
  return (
    <>
      <section className="about-section about-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-2">
              <div className="about_wrapper">
                <figure className="mb-0 about-image1">
                  <img src="about-img/about-image1.jpg" alt="" />
                </figure>
                <figure className="mb-0 about-image2">
                  <img
                    src="about-img/about-image2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </figure>

                <figure className="mb-0 about-dot">
                  <img
                    src="about-img/about-dot.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <figure className="mb-0 about-leftcircle">
                  <img
                    src="about-img/about-leftcircle.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <figure className="mb-0 about-layer">
                  <img
                    src=".about-img/about-layer.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure> 
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-1">
              <div className="about_content aos-init" data-aos="fade-right" data-aos-delay={100}>
                <h3><strong>About us</strong></h3>

                <h2 className="text-white">
                  {" "}
                  We help clients invent their future.
                </h2>
                <p className="text1">
                  We Are The Fast Growing Consulting And Outsourcing Solution
                  Company. We Have A Team Of Innovative Experienced
                  Professionals Who Think And Act As An Extension To Our
                  Customer's Development Teams.
                </p>
                <p className="text2">
                  Our Prime Goal Is To Meet Our Customer's Expectations By
                  Delivering Smart And Reliable Software Applications To Help
                  Them Grow Their Business.
                </p>
                <Link to="/adout-page"
                  className="read_more text-white text-decoration-none"
                >
                  Read More
                  <figure className="mb-0">
                    <img
                      src="about-img/button-arrow.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <figure className="mb-0 about-rightimage">
    <img
      src="about-img/about-rightimage.png"
      alt=""
      className="img-fluid"
    />
  </figure> */}
        {/* <figure className="mb-0 about-rightcircle">
          <img
            src="about-img/about-rightcircle.png"
            alt=""
            className="img-fluid"
          />
        </figure> */}
      </section>
    </>
  );
}

export default AboutNew;
