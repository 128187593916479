import React from "react";
import './ReadMore.css'
import Gfooter from "../Component/Gfooter";

function ProductReadMore() {
  return (
    <div className="read-top">

{/* <div className="container-fluid about-landscape">
        <div className="main-img landscape-mob col-lg-12 col-md-12 col-sm-12">
          <img src="about-img/Artboard 5.jpg" alt="banner of about"/>
<div className="readmore-banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Products</h1>
                  <p>Doidunt eget semper nec ruam sed hendrerit morbi ac feliseao augue pellentesue morbi acer.</p>
                  <div className="readmore-box">
                    <span className="mb-0 box-home">Products</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">ReadMore</span>
                  </div>
                </div>
        </div>
      </div> */}

      <div className="container">
        <h1 className="our-heading">
          <strong>Our Products</strong>
        </h1>
      </div>
      <div className=" container">
        <h2 className="productmore-heading">
          <strong>Global Port Management System</strong>
        </h2>
      </div>
      <div>
        <div className="container product-all">
          <div className="row">
            <div className="productmore-detail col-lg-6 col-md-12 col-sm-12" data-aos="zoom-in" data-aos-delay={140}>
              <div className="productmore-info">
                <img src="product-img/yardfeacture.jpg" alt=" yard pic" />
                <div className="productmore-content">
                  <h2 className="key-title">
                    <strong>Key Features :-</strong>
                  </h2>
                  <ul>
                    <li>
                      <p>
                        <b>Comprehensive Operational View : </b>
                        GPMS integrates data from all terminal platforms to
                        provide a detailed operational overview in real-time.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Efficient Cargo Management : </b>
                        Facilitates precise management of cargo arrival and
                        departure times, optimizing scheduling and resource
                        allocation.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Container Yard Management : </b>
                        Enhances efficiency in container handling and storage
                        within the terminal yard.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Queue and Straddle Crane Management : </b>
                        Improves operational flow by optimizing queue management
                        and straddle crane utilization.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="benefits col-lg-6 col-md-12 col-sm-12">
              <h2 className="benefits-title">
                <strong>Benefits :-</strong>
              </h2>
              <ul>
                <li>
                  <p>
                    <b>Cost Savings : </b>
                    Reduces operational costs by optimizing resource usage and
                    minimizing downtime.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Enhanced Efficiency : </b>
                    Streamlines processes to improve turnaround times and
                    overall operational efficiency.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Real-time Insights : </b>
                    Provides actionable insights to make informed decisions and
                    improve terminal performance.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Competitive Advantage : </b>
                    Positions terminals to compete effectively in the global
                    maritime industry through enhanced operational capabilities.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container para-bottom">
        <p>
          Experience the power of GPMS in transforming terminal operations,
          driving efficiency, and achieving cost savings. Revolutionize your
          terminal management with Global SunTec Solutions and stay ahead in the
          competitive maritime landscape.
        </p>
      </div>

      <div className=" container">
        <h2 className="productmore-heading">
          <strong>Global iFile Management System</strong>
        </h2>
      </div>

      <div className="container product-all">
        <div className="row">
          <div className="benefits2 col-lg-6 col-md-12 col-sm-12">
            <h2 className="benefits-title">
              <strong>Benefits :-</strong>
            </h2>
            <ul>
              <li>
                <p>
                  <b>Improved Productivity : </b>
                  Reduces time spent on document handling and retrieval,
                  enabling focus on core business activities.
                </p>
              </li>
              <li>
                <p>
                  <b>Enhanced Collaboration : </b>
                  Facilitates seamless collaboration across departments or
                  remote teams with centralized document access.
                </p>
              </li>
              <li>
                <p>
                  <b>Cost Savings : </b>
                  Minimizes paper usage, storage costs, and operational
                  inefficiencies associated with manual document handling.
                </p>
              </li>
              <li>
                <p>
                  <b>Data Security : </b>
                  Ensures data integrity and confidentiality with encryption and
                  access control features.
                </p>
              </li>
            </ul>
          </div>

          <div className="productmore-detail col-lg-6 col-md-12 col-sm-12" data-aos="zoom-in" data-aos-delay={140}>
            <div className="product2-info">
              <img src="project-pic/ifilereadmore.jpg" alt=" yard pic" />

              <div className="product2-content">
                <h2 className="key-title">
                  <strong>Key Features :-</strong>
                </h2>
                <ul>
                  <li>
                    <p>
                      <b>Document Capture and Storage : </b>
                      Digitally captures and securely stores documents in a
                      centralized repository.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Document Indexing and Retrieval : </b>
                      Facilitates quick and easy retrieval of documents through
                      advanced indexing and search functionalities.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Version Control and Collaboration : </b>
                      Manages document versions and enables real-time
                      collaboration among teams.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Security and Compliance : </b>
                      Implements robust security measures to protect sensitive
                      documents and ensure regulatory compliance.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container para-bottom">
        <p>
          Experience the efficiency and reliability of Global iFile in managing
          your organization's documents. Streamline workflows, enhance
          collaboration, and
        </p>
      </div>

      <div className=" container">
        <h2 className="productmore-heading">
          <strong>Global iParking Management System</strong>
        </h2>
      </div>
      <div>
        <div className="container product-all">
          <div className="row">
            <div className=" productmore-detail col-lg-6 col-md-12 col-sm-12" data-aos="zoom-in" data-aos-delay={40}>
              <div className="productmore-info">
                <img src="product-img/iparking2.png" alt=" product pic" />
                <div className="productmore-content">
                  <h2 className="key-title">
                    <strong>Key Features:-</strong>
                  </h2>
                  <ul>
                    <li>
                      <p>
                        <b>Real-Time Parking Availability :</b>
                        Provides live updates on available parking spaces,
                        reducing search time for motorists.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Automated Vehicle Identification : </b>
                        Uses advanced technology to capture vehicle information,
                        facilitating seamless entry and exit.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Payment Integration :</b>
                        Supports various payment methods for convenient and
                        efficient fee collection.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Analytics and Reporting : </b>
                        Generates insights into parking occupancy, trends, and
                        revenue performance.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="benefits col-lg-6 col-md-12 col-sm-12">
              <h2 className="benefits-title">
                <strong>Benefits:-</strong>
              </h2>
              <ul>
                <li>
                  <p>
                    <b>Enhanced User Experience :</b>
                    Minimizes congestion and wait times with real-time parking
                    information and quick transactions.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Optimized Space Utilization :</b>
                    Maximizes parking facility efficiency by effectively
                    managing space allocation.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Increased Revenue :</b>
                    Improves revenue generation through accurate fee collection
                    and data-driven pricing strategies.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Operational Efficiency :</b>
                    Reduces operational costs with automated processes and
                    streamlined management.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container para-bottom">
        <p>
          Discover how Global iParking can transform your parking facility into
          a modern, efficient operation. Elevate user satisfaction, optimize
          revenue, and
        </p>
      </div>

      <div className=" container">
        <h2 className="productmore-heading">
          <strong>Global Toll Plaza Management System</strong>
        </h2>
      </div>

      <div className="container product-all">
        <div className="row">
          <div className="benefits2 col-lg-6 col-md-12 col-sm-12">
            <h2 className="benefits-title">
              <strong>Benefits:-</strong>
            </h2>
            <ul>
              <li>
                <p>
                  <b>Improved Efficiency:</b>
                  Streamlines toll collection processes, reducing transaction
                  times and operational costs.
                </p>
              </li>
              <li>
                <p>
                  <b>Enhanced Revenue Collection:</b>
                  Ensures accurate toll charges, minimizing revenue losses and
                  enhancing financial transparency.
                </p>
              </li>
              <li>
                <p>
                  <b>Customer Satisfaction:</b>
                  Provides a seamless experience for motorists with faster
                  transaction processing and reduced queue lengths.
                </p>
              </li>
              <li>
                <p>
                  <b>Operational Insights:</b>
                  Enables data-driven decisions through comprehensive analytics
                  and reporting capabilities.
                </p>
              </li>
            </ul>
          </div>

          <div className=" productmore-detail col-lg-6 col-md-12 col-sm-12" data-aos="zoom-in" data-aos-delay={40}>
            <div className="product2-info">
              <img src="project-pic/tollreadmore.jpg" alt=" product pic" />

              <div className="product2-content">
                <h2 className="key-title">
                  <strong>Key Features:-</strong>
                </h2>
                <ul>
                  <li>
                    <p>
                      <b>Advanced Data Integration:</b>
                      Integrates live camera feeds and real-time data processing
                      for accurate vehicle identification and toll calculation.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>High Accuracy:</b>
                      Achieves a 95-97% accuracy rate in vehicle recognition,
                      reducing errors and revenue leakage.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Traffic Management:</b>
                      Enhances traffic flow by minimizing wait times and
                      congestion at toll plazas.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Automated Reporting: </b>
                      Generates real-time reports on traffic volume, revenue
                      collection, and operational efficiency.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container para-bottom">
        <p>
          Experience the reliability and efficiency of GTPMS in transforming
          toll plaza operations. Optimize traffic management, enhance revenue
          collection, and improve overall operational efficiency with Global
          Toll Plaza Management System from Global SunTec Solutions.
        </p>
      </div>
<Gfooter/>
    </div>
  );
}

export default ProductReadMore;
