import React from 'react';

const VideoLoader = () => {
    return (
        <div className="video-loader">
            <video className='loader-video'autoPlay loop muted width='300px'>
                {/* <source src="/Gsuntec.mp4" type="video/mp4" /> */}
                {/* <source src="Gsuntec1.mp4" type="video/mp4" /> */}
                <source src="../../video/gsuntec.mp4" type="video/mp4" />
                {/* <img src='Gsuntec1.gif' alt='gsuntec'/> */}
                Your browser does not support the video tag.
            </video>
            {/* <br />
            <h3 className='multicolor-text'><strong>GsunTec</strong></h3> */}
        </div>
    );
};

export default VideoLoader;
