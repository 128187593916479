function Card() {
  return (
    <div className="container card-top">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-4">
          <div className="custom-card">
            <div className="card-image">
              <img src="blog-img/img8.jpg" alt="Blockchain Developers" />
            </div>
            <div className="card-text">
              <p>
                <h4>
                  How is Blockchain as a Service Empowering
                  <br /> Blockchain Developers?
                </h4>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="custom-card">
            <div className="card-image">
              <img src="blog-img/img9.jpg" alt=" Blockchain as a Service" />
            </div>
            <div className="card-text">
              <p>
                <h4>
                  Blockchain as a Service (BaaS):
                  <br /> Benefits and Impact on Businesses
                </h4>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="custom-card">
            <div className="card-image">
              <img src="blog-img/img10.jpg" alt="Smart Contract Coding" />
            </div>
            <div className="card-text">
              <p>
                <h4>
                  Smart Contract Coding:
                  <br />
                  Standards and Best Practices
                </h4>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
