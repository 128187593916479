import React from 'react';
import './career.css';
import Code from './Code';
import Section from './Section';
import Recognised from './Recognised';
import Carousel from './Carousel';
import Formjob from './Formjob';
import ClientSpeack from './ClientSpeack';
import Gfooter from '../Component/Gfooter';
import ClientSpeackData from '../Component/ClientSpeackData';



function Add() {
    return ( 
        <div className='career-top'>
            {/* <div className='career-img'>
            <img src="career-img/Career Page.jpg" alt="career banner"/>
            <div className="career-banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Careers</h1>
                  <p>Doidunt eget semper nec ruam sed hendrerit morbi ac feliseao augue pellentesue morbi acer.</p>
                  <div className="career-box">
                    <span className="mb-0 box-home">Home</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Careers</span>
                  </div>
                </div>
                </div> */}
      <Code/>
        <Carousel/>
        <Section/>
        {/* <Recognised/> */}
        {/* <ClientSpeackData/>   */}
        <Formjob/>
<Gfooter/>
        </div>
     );
}

export default Add;