
import React from 'react';
import { Link } from 'react-router-dom';

function Card1() {
    return (
        <div className='container card1-top'>
            <div className='row wow fadeInUp'  style={{
        animationName: 'fadeInUp',
        visibility: 'visible'
      }}>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div className="card blog-card blog-card-1">
                        <img src="blog-img/Blogimg1.jpg" alt="Blog 1" className="card-img-top1" />
                            <Link to="/blog1">
                        <div className="card-body">
                            <p className="card-title-1">Blog 1</p>
                            <p className="small-desc">
                                The Future of AI in Business: Trends to Watch
                            </p>
                            <div className="go-corner">
                                <div className="go-arrow">→</div>
                            </div>
                        </div>
                            </Link>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div className="card blog-card blog-card-1">
                        <img src="blog-img/Blogimg2.jpg" alt="Blog 2" className="card-img-top" />
                        <Link to="/blog2">
                        <div className="card-body">
                            <p className="card-title-1">Blog 2</p>
                            <p className="small-desc">
                                How Business Intelligence Transforms Decision-Making
                            </p>
                            <div className="go-corner">
                                <div className="go-arrow">→</div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div className="card blog-card blog-card-1">
                        <img src="blog-img/Blogimg3.jpg" alt="Blog 3" className="card-img-top3" />
                        <Link to="/blog3">
                        <div className="card-body">
                            <p className="card-title-1">Blog 3</p>
                            <p className="small-desc">
                                Generative AI: Transforming Creativity and Productivity in 2024
                            </p>
                            <div className="go-corner">
                            <div className="go-arrow">→</div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card1;
