import React from "react";
import "./DataScience.css";
function DataScience() {
  return (
    <>
      <div className="container data-science-sec">
        <h1 className="data-science-heading">
          <strong>Data Science</strong>
        </h1>
        <div className="row data-science-data for-effect">
          <div className="data-science-image col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="service-pic/datascience-1.jpeg" alt="testing pic" />
          </div>
          <div className="data-science-content col-lg-6 col-md-12 col-sm-12">
            <p className="data-science-para" data-aos="fade-up" data-aos-delay={100}>
              <h3><b>Big Data</b></h3>
              Specializes in Big Data analysis. This involves taking a
              structured approach, involving the right people to do the job and
              selecting the right technology.We enable clients to improve their
              decision-making capability by providing them access to a wide
              range of data analytics solutions, services, and resources. Your
              data is growing every day & you can't stop it - but you can
              control it with Big Data. Let's find the business in your data!
            </p>
          </div>
          </div>
      </div>
      <br/><br/>

      <div className="container testing-sec ">
        <div className="row data-science-data for-common-effect">
          <div className="data-science-content col-lg-6 col-md-12 col-sm-12">
            <p className="data-science-para" data-aos="fade-up" data-aos-delay={100}>
              <h3><b>Cloud Computing</b></h3>
              At some point enterprises go through the decision-making process.
              Arguments can be made both ways and it is important to develop the
              right strategy. Using cloud technology provides many benefits like
              accelerated time-to-market, enhanced organizational flexibility
              and scalability, rapid resource provisioning, and lower total cost
              of ownership.
            </p>
          </div>
          <div className="data-science-image col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="service-pic/datascience-2.jpeg" alt="testing pic" />
          </div>
        </div>
      </div>
 

      
    </>
  );
}

export default DataScience;
