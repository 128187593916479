import React from 'react';
import {Link} from 'react-router-dom'

function Code() {
  return (  
  <div className="container-fluid code-top">
    {/* <section className="banner1" data-aos="fade-up" data-aos-delay={80}>
    <div className="row">
      <div
        className="col-md-6 text-center"
        data-aos="fade-right"
        data-aos-delay={80}
      >
        <div className="banner-img-thumb">
          <img
            className="img-fluid"
            src="career-img/Banner.png"
            alt="Systango Career"
          />
        </div>
      </div>
      <div className="col-md-6 data1" data-aos="fade-left" data-aos-delay={80}>
        <h1>
          We’re Superheroes/ Honest <br/> Devils/ Foodies/ Perfectionists
          <span className="dot2" />
        </h1>
        <p className="mb-5">
          “Join us in an open, transparent working environment with lots of<br/>
          opportunities and freedom. Together we are a team, not a bunch of
          individuals!”
        </p>
        <Link to = "/explore-job"
          className="btn1 black-btn text-uppercase filled-btn mt-3"
        >
          Explore Job Openings
         <span className='arrow'><i className="fa-solid fa-arrow-right" />
         </span>
        </Link>
      </div>
    </div>
</section> */}


<section className="mainContent"
 data-aos="fade-up" data-aos-delay={100}
 >
  <div className="container-fluid color-bgWhite pb-5">
    <div className="container big-container ">
      <div className="row">
        <div
          className="col-md-12 text-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="top-content-wrapper">
            <h1>Our Work Culture.</h1>
            <p>
              {/* We have created a work environment where Systians feel encouraged,
              heard, and supported. We cherish inclusivity &amp; diversity at
              our workplace where everyone has an equal opportunity to grow. */}
              Join us to be part of an exciting journey where your ideas and contributions truly matter!
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-6 text-center"
          data-aos="fade-right"
          data-aos-delay={100}
        >
          <div className="div_box">
            <div className="image_box_car">
              <img
                className="img-fluid"
                src="career-img/work2.jpg"
                alt="Gsuntec Career"
              />
            </div>
            <div className="content_box_car">
              <h2>Enjoy What You Do</h2>
              <p>
              At Global Suntec Solutions, we foster a culture of innovation, collaboration, and growth. As a fast-paced startup, every team member plays a critical role in driving change and solving real-world problems. Our flat structure encourages open communication, empowering you to take ownership and make impactful decisions.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 text-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="div_box">
            <div className="image_box_car">
              <img
                className="img-fluid"
                src="career-img/work1.jpg"
                alt="GsunTec Career"
              />
            </div>
            <div className="content_box_car">
              <h2>Come Up With Ideas</h2>
              <p>
              You’ll thrive in a supportive environment that offers ample opportunities for learning and personal growth. We value work-life balance, and our fun, inclusive culture brings the team together with activities, team-building events, and innovation challenges.
              </p>
            </div>
          </div>
        </div>
        {/* <div
          className="col-md-4 text-center"
          data-aos="fade-right"
          data-aos-delay={100}
        >
          <div className="div_box">
            <div className="image_box_car">
              <img
                className="img-fluid"
                src="career-img/group-3.png"
                alt="GsunTec Career"
              />
            </div>
            <div className="content_box_car">
              <h2>Thrive As A Tribe</h2>
              <p>
              Join us to be part of an exciting journey where your ideas and contributions truly matter!
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</section>

  </div>

  );
}

export default Code;