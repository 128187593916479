import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Button() {
  return (
    <div className="container button-text">
      <div className="row">
        <div
          className="col-md-12 flex-wrap"
          data-aos="fade-up"
          data-aos-delay={40}
        >
          <h2 className="text-center section_heading">
            Have a Look at Some of Our Inspiring Crafts.
          </h2>
          <div className="spacer30 reduceMobile" />
          <h4 className="text-center subheading_ourwork">Industries.</h4>
          <div
            className="filter-button-group"
            data-aos="fade-up"
            data-aos-delay={80}
          >
            <button
              data-filter="*"
              className="btn  primary btn active text-capitalize mright15 mbottom20"
            >
              All
            </button>
            <button
              data-filter=".automation"
              className="btn  primary btn active text-capitalize mright15 mbottom20"
            >
              Automation
            </button>
            <button
              data-filter=".b2b-solutions"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              B2B Solutions
            </button>
            <button
              data-filter=".e-commerce"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              E-Commerce
            </button>
            <button
              data-filter=".enterprise-mobility"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Enterprise Mobility
            </button>
            <button
              data-filter=".entertainment"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Entertainment
            </button>
            <button
              data-filter=".fintech"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Fintech
            </button>
            <button
              data-filter=".health-fitness"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Health &amp; Fitness
            </button>
            <button
              data-filter=".igaming-and-fantasy-sports"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              iGaming and Fantasy Sports
            </button>
            <button
              data-filter=".iot"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              IoT
            </button>
            <button
              data-filter=".machine-learning"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Machine Learning
            </button>
            <button
              data-filter=".music-entertainment"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Music &amp; Entertainment
            </button>
            <button
              data-filter=".real-estate"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Real Estate
            </button>
            <button
              data-filter=".shopping-app"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Shopping App
            </button>
            <button
              data-filter=".smart-city"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Smart City
            </button>
            <button
              data-filter=".social-networking"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Social Networking
            </button>
            <button
              data-filter=".specialities-and-services"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Specialities and Services
            </button>
            <button
              data-filter=".travel-hospitality"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Travel &amp; Hospitality
            </button>
          </div>


          <div className="spacer30 reduceMobile" />
          <h4 className="text-center subheading_ourwork">Technologies.</h4>
          <div
            className="filter-button-group"
            data-aos="fade-up"
            data-aos-delay={1000}
          >
            <button
              data-filter="*"
              className="btn  primary btn active active text-capitalize mright15 mbottom20"
            >
              All
            </button>
            <button
              data-filter=".android-app"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Android App
            </button>
            <button
              data-filter=".angular-js"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Angular JS
            </button>
            <button
              data-filter=".blockchain"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Blockchain
            </button>
            <button
              data-filter=".devops"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Devops
            </button>
            <button
              data-filter=".digital-marketing"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Digital Marketing
            </button>
            <button
              data-filter=".django"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Django
            </button>
            <button
              data-filter=".flutter-app"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Flutter App
            </button>
            <button
              data-filter=".innovation"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Innovation
            </button>
            <button
              data-filter=".ios"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Ios
            </button>
            <button
              data-filter=".iot"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              IoT
            </button>
            <button
              data-filter=".java"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Java
            </button>
            <button
              data-filter=".mobile-apps"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Mobile Apps
            </button>
            <button
              data-filter=".node"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Node
            </button>
            <button
              data-filter=".php"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              PHP
            </button>
            <button
              data-filter=".python"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Python
            </button>
            <button
              data-filter=".qa"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              QA
            </button>
            <button
              data-filter=".react-js"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              React JS
            </button>
            <button
              data-filter=".react-native-app"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              React Native App
            </button>
            <button
              data-filter=".ror"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              ROR
            </button>
            <button
              data-filter=".vue-js"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Vue JS
            </button>
            <button
              data-filter=".web-apps"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Web Apps
            </button>
            <button
              data-filter=".zoho-analytics"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Zoho Analytics
            </button>
            <button
              data-filter=".zoho-integrations"
              className="btn  primary btn active  text-capitalize mright15 mbottom20"
            >
              Zoho Integrations
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Button;
