import React from "react";
import { useState } from "react";
import {Link} from 'react-router-dom';
import Gfooter from "../Component/Gfooter";

function OpenPos() {
  // Step 1: Set up state for the search input value
  const [searchText, setSearchText] = useState("");

  // Step 2: Handle input changes
  const handleInputChange = (event) => {
    setSearchText(event.target.value); // Update state with input value
  };

  // Step 3: Handle clear button click
  const handleClearClick = () => {
    setSearchText(""); // Clear the input by resetting state to an empty string
  };

  // Step 1: Set up state to manage dropdown visibility
  const [isOpen, setIsOpen] = useState(false);

  // Step 2: Toggle dropdown visibility
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle state between true and false
  };
  return (
    <>


<div className="project-bg-img"
        style={{
          backgroundImage: `url("career-img/banner.jpg")`
        }}></div>
      <div className="container-fluid">
        <div className="bg-content">
          <div
            attr-name="title"
            className="kch-d-flex kch-justify-content-center kch-mb-12 kch-max-w-400 kch-heading-color kch-text-heading kch-font-weight-700 kch-text-center kch-text-center-sm kch-overflow-hidden kch-header-title-max-height"
          >
            Be a part of building something <br />great
          </div>
          <div className="kch-d-flex kch-justify-content-center kch-text-center-sm">
            <div className="kch-mb-8 kch-d-flex kch-justify-content-start ">
              <p
                className="kch-btn kch-btn-primary"
              >
                Browse all jobs
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid openpos-top">
        <div className="container">
          <h1 className="job-heading">
            <strong>Open Position</strong>
          </h1>
        </div>
        <div className="container">
          <div className="row">
            <div className=" col-lg-3 col-md-6 col-sm-12">
              <div className="box58">
                <div
                  id="kh-embed-jobs-filters"
                  className="kh-col-lg-3 kh-col-sm-12 kh-pr-lg-3 kh-mb-"
                >
                  <div className=" kh-card">
                    <div className="kh-card-body">
                      <ul className="kh-list-unstyled kh-overflow-x-hidden kh-mb-0">
                        <li>
                          <div className=" kh-d-flex kh-align-items-center">
                            <div className=" kh-input-search kh-position-relative kh-w-100 kh-mb-0 kh-d-flex kh-align-items-center">
                              <span className="sea-icon fa-solid fa-magnifying-glass kh-icon kh-icon-sm kh-position-absolute kh-ml-8 kh-d-flex kh-align-items-center" />
                              {/* <i className=" box53 fa-solid fa-magnifying-glass"></i> */}
                              <input
                                type="search"
                                value={searchText}
                                onChange={handleInputChange} // Update state on input change
                                placeholder=" Search..."
                              />

                              <span>
                                <i
                                  className="fa-solid fa-filter-circle-xmark button-clr"
                                  onClick={handleClearClick}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="kh-mt-3">
                          <div className="kh-accordion-item">
                            <div className="kh-accordion-title">
                              <div className="kh-d-flex kh-align-items-center kh-justify-content-between kh-cursor-pointer">
                                <span className="kh-font-large">
                                  Department
                                </span>
                                <span className="kh-icon kh-d-flex kh-align-items-center kh-justify-content-center">
                                  <i
                                    className="fa-solid fa-chevron-down  drop-icon"
                                    onClick={handleToggleDropdown}
                                  ></i>
                                </span>
                              </div>
                            </div>

                            {/* Step 4: Conditional rendering of dropdown list */}
                            {isOpen && (
                              <ul
                                style={{
                                  position: "relative",
                                  top: -5,
                                  left: 40,
                                  margin: 0,
                                  padding: 0,
                                  listStyle: "none",
                                  backgroundColor: "#fff",
                                  // border: '1px solid #ddd',
                                  // boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                                  width: "150px",
                                  zIndex: 1,
                                }}
                              >
                                <li className=" kh-form-check">
                                  <input
                                    className="kh-form-check-input kh-cursor-pointer"
                                    type="checkbox"
                                    id={13752}
                                    defaultValue={13752}
                                    name="departments"
                                  />
                                  <label
                                    className="kh-form-check-label kh-cursor-pointer kh-text-truncate-2 kh-pl-2"
                                    htmlFor={13752}
                                  >
                                    Engineering
                                  </label>
                                </li>
                              </ul>
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              <div id="kh-job-wedget" className="col-md-12 col-sm-12  ">
                <div className="kh-mb-3 kh-row kh-no-gutters kh-w-100">
                  <h1
                    className="engi-data kh-font-weight-600 kh-col-sm-12 kh-mb-16 kh-mt-0 kh-d-flex kh-align-items-center kh-flex-wrap kh-text-truncate-2"
                    title="Engineering"
                  >
                    Engineering
                    <span className="kh-text-lowercase  no-jobs kh-badge kh-badge-pill kh-jobs-bg-color kh-ml-2 kh-font-weight-100 kh-text-small kh-px-3 kh-py-1">
                      6 jobs
                    </span>
                  </h1>

                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div
                          className=" 
             kh-col-sm-12 kh-mb-9 kh-col-md-6"
                        >
                          <Link to="/project1"
                            className="kh-card kh-job-card kh-h-100 kh-job-container"
                          >
                            <div className="kh-card-body kh-d-flex kh-flex-column kh-py-3">
                              <div className="kh-d-flex kh-align-items-center kh-justify-content-between">
                                <div className="kh-w-100">
                                  <div className="kh-d-flex kh-align-items-center kh-mb-1 kh-flex-wrap">
                                    <h3
                                      className="kh-job-title kh-text-truncate-1 kh-text-dark kh-font-weight-600 kh-mb-0 kh-mr-2"
                                      title=" Qlikview Developer"
                                    >
                                      Qlikview Developer
                                    </h3>
                                  </div>
                                  <div className="kh-d-flex kh-text-secondary kh-align-items-center mt-auto">
                                    <div className="kh-d-flex kh-align-items-center kh-flex-wrap">
                                      <span
                                        className="kh-font-large kh-text-capitalize kh-text-truncate-1 kh-mr-1"
                                        title="Remote - India"
                                      >
                                        India
                                      </span>
                                      {/* <span
                            className="kh-font-normal"
                            title="Crystal IT Park - 3rd Floor, Crystal IT Park - 4th Floor"
                          >
                            {" "}
                            +2 locations{" "}
                          </span> */}
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <span
                                        className="kh-font-normal kh-text-capitalize kh-text-truncate-1"
                                        title="3-5 years"
                                      >
                                        3 To 5 years{" "}
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <p className="kh-font-normal kh-clear-magin">
                                        Full Time
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="kh-px-1 kh-d-none kh-d-md-block ">
                                  <span className="kh-icon kh-icon-lg kh-my-auto kh-rotate-315 kh-d-none">
                                    <i className="fa-solid fa-circle-arrow-right "></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="kh-col-sm-12 kh-mb-16 kh-col-md-6">
                          <Link to="/project2"
                            className="kh-card kh-job-card kh-h-100 kh-job-container"
                          >
                            <div className="kh-card-body kh-d-flex kh-flex-column kh-py-3">
                              <div className="kh-d-flex kh-align-items-center kh-justify-content-between">
                                <div className="kh-w-100">
                                  <div className="kh-d-flex kh-align-items-center kh-mb-1 kh-flex-wrap">
                                    <h3
                                      className="kh-job-title kh-text-truncate-1 kh-text-dark kh-font-weight-600 kh-mb-0 kh-mr-2"
                                      title="Python Developer"
                                    >
                                      Python Developer
                                    </h3>
                                  </div>
                                  <div className="kh-d-flex kh-text-secondary kh-align-items-center mt-auto">
                                    <div className="kh-d-flex kh-align-items-center kh-flex-wrap">
                                      <span
                                        className="kh-font-large kh-text-capitalize text-nowrap kh-text-truncate-1"
                                        title="Indore"
                                      >
                                        Indore
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <span
                                        className="kh-font-normal kh-text-capitalize kh-text-truncate-1"
                                        title="2 to 5 years"
                                      >
                                        2 to 5 years{" "}
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <p className="kh-font-normal kh-clear-magin">
                                        Full Time
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="kh-px-1 kh-d-none kh-d-md-block ">
                                  <span className="kh-icon kh-icon-lg kh-my-auto kh-rotate-315 kh-d-none">
                                    <i className="fa-solid fa-circle-arrow-right"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="kh-col-sm-12 kh-mb-16 kh-col-md-9">
                          <Link to="/project3"
                            className="kh-card kh-job-card kh-h-100 kh-job-container"
                          >
                            <div className="kh-card-body kh-d-flex kh-flex-column kh-py-3">
                              <div className="kh-d-flex kh-align-items-center kh-justify-content-between">
                                <div className="kh-w-100">
                                  <div className="kh-d-flex kh-align-items-center kh-mb-1 kh-flex-wrap">
                                    <h3
                                      className="kh-job-title kh-text-truncate-1 kh-text-dark kh-font-weight-600 kh-mb-0 kh-mr-2"
                                      title="QA Automation"
                                    >
                                      QA Automation
                                    </h3>
                                  </div>
                                  <div className="kh-d-flex kh-text-secondary kh-align-items-center mt-auto">
                                    <div className="kh-d-flex kh-align-items-center kh-flex-wrap">
                                      <span
                                        className="kh-font-large kh-text-capitalize text-nowrap kh-text-truncate-1"
                                        title="Indore"
                                      >
                                        Indore
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <span
                                        className="kh-font-normal kh-text-capitalize kh-text-truncate-1"
                                        title="4 to 5 years"
                                      >
                                        4 to 5 years{" "}
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <p className="kh-font-normal kh-clear-magin">
                                        Full Time
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="kh-px-1 kh-d-none kh-d-md-block ">
                                  <span className=" kh-icon kh-icon-lg kh-my-auto kh-rotate-315 kh-d-none">
                                    <i className="fa-solid fa-circle-arrow-right"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="kh-col-sm-12 kh-mb-16 kh-col-md-9">
                          <a
                            className="kh-card kh-job-card kh-h-100 kh-job-container"
                            href="/"
                          >
                            <div className="kh-card-body kh-d-flex kh-flex-column kh-py-3">
                              <div className="kh-d-flex kh-align-items-center kh-justify-content-between">
                                <div className="kh-w-100">
                                  <div className="kh-d-flex kh-align-items-center kh-mb-1 kh-flex-wrap">
                                    <h3
                                      className="kh-job-title kh-text-truncate-1 kh-text-dark kh-font-weight-600 kh-mb-0 kh-mr-2"
                                      title="Software Engineer-iOS"
                                    >
                                      Software Engineer-iOS
                                    </h3>
                                  </div>
                                  <div className="kh-d-flex kh-text-secondary kh-align-items-center mt-auto">
                                    <div className="kh-d-flex kh-align-items-center kh-flex-wrap">
                                      <span
                                        className="kh-font-large kh-text-capitalize text-nowrap kh-text-truncate-1"
                                        title="Indore"
                                      >
                                        Indore
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <span
                                        className="kh-font-normal kh-text-capitalize kh-text-truncate-1"
                                        title="4 to 6 yrs"
                                      >
                                        4 to 6 yrs{" "}
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <p className="kh-font-normal kh-clear-magin">
                                        Full Time
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="kh-px-1 kh-d-none kh-d-md-block ">
                                  <span className="kh-icon kh-icon-lg kh-my-auto kh-rotate-315 kh-d-none">
                                    <i className="fa-solid fa-circle-arrow-right"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="kh-col-sm-12 kh-mb-16 kh-col-md-9">
                          <a
                            className="kh-card kh-job-card kh-h-100 kh-job-container"
                            href="/"
                          >
                            <div className="kh-card-body kh-d-flex kh-flex-column kh-py-3">
                              <div className="kh-d-flex kh-align-items-center kh-justify-content-between">
                                <div className="kh-w-100">
                                  <div className="kh-d-flex kh-align-items-center kh-mb-1 kh-flex-wrap">
                                    <h3
                                      className="kh-job-title kh-text-truncate-1 kh-text-dark kh-font-weight-600 kh-mb-0 kh-mr-2"
                                      title="Technical Architect- Python"
                                    >
                                      Technical Architect- Python
                                    </h3>
                                  </div>
                                  <div className="kh-d-flex kh-text-secondary kh-align-items-center mt-auto">
                                    <div className="kh-d-flex kh-align-items-center kh-flex-wrap">
                                      <span
                                        className="kh-font-large kh-text-capitalize kh-text-truncate-1 kh-mr-1"
                                        title="Austriya"
                                      >
                                        Austriya
                                      </span>
                                      {/* <span
                            className="kh-font-normal"
                            title="Crystal IT Park - 4th Floor, Remote - India"
                          >
                            {" "}
                            +2 locations{" "}
                          </span> */}
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <span
                                        className="kh-font-normal kh-text-capitalize kh-text-truncate-1"
                                        title="8+ Years"
                                      >
                                        8+ Years{" "}
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <p className="kh-font-normal kh-clear-magin">
                                        Full Time
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="kh-px-1 kh-d-none kh-d-md-block ">
                                  <span className="kh-icon kh-icon-lg kh-my-auto kh-rotate-315 kh-d-none">
                                    <i className="fa-solid fa-circle-arrow-right"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="kh-col-sm-12 kh-mb-16 kh-col-md-9">
                          <a
                            className="kh-card kh-job-card kh-h-100 kh-job-container"
                            href="/"
                          >
                            <div className="kh-card-body kh-d-flex kh-flex-column kh-py-3">
                              <div className="kh-d-flex kh-align-items-center kh-justify-content-between">
                                <div className="kh-w-100">
                                  <div className="kh-d-flex kh-align-items-center kh-mb-1 kh-flex-wrap">
                                    <h3
                                      className="kh-job-title kh-text-truncate-1 kh-text-dark kh-font-weight-600 kh-mb-0 kh-mr-2"
                                      title="Technical Architect-(Java + NodeJS)"
                                    >
                                      Technical Architect-Java
                                    </h3>
                                  </div>
                                  <div className="kh-d-flex kh-text-secondary kh-align-items-center mt-auto">
                                    <div className="kh-d-flex kh-align-items-center kh-flex-wrap">
                                      <span
                                        className="kh-font-large kh-text-capitalize text-nowrap kh-text-truncate-1"
                                        title="Indore"
                                      >
                                        Indore
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <span
                                        className="kh-font-normal kh-text-capitalize kh-text-truncate-1"
                                        title="10+ Years"
                                      >
                                        10+ Years{" "}
                                      </span>
                                      <div className="kh-dot kh-dot-xs kh-bg-slate-900 kh-mx-2" />
                                      <p className="kh-font-normal kh-clear-magin">
                                        Full Time
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="kh-px-1 kh-d-none kh-d-md-block ">
                                  <span className=" kh-icon kh-icon-lg kh-my-auto kh-rotate-315 kh-d-none">
                                    <i className="fa-solid fa-circle-arrow-right"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 <Gfooter/>
    </>
  );
}

export default OpenPos;
