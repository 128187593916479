import React from 'react';
function Section() {
    return (  
<>
  <section className="looking-perks" data-aos="fade-up" data-aos-delay={100}>
    <div className="inner-wrap">
      <div className="container image"
      style={{backgroundImage: 'url("career-img/bg.jpg")'}}
      >
        <div className="container big-container">
          <div className="row">
            <div
              className="col-lg-12"
              data-aos="fade-down"
              data-aos-delay={100}
              >
              <div className="content-wrap text-center">
                <h2>Perks Of Becoming A Systian.</h2>
                {/* <p>We have got you covered.</p> */}
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="looking-perks-box">
                <img src="career-img/img1.jpg" alt="Perks Of Becoming A Systian"/>
                <p>
                  Learning &amp; development opportunities to build career on
                  next-gen technologies.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="looking-perks-box">
                <img src="career-img/img2.png" alt="passionate developers logo"/>
                <p>
                  Be part of a close-knit community of passionate developers,
                  designers, &amp; marketers.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="looking-perks-box">
                <img src="career-img/img3.png" alt="Competitive compensation logo"/>
                <p>
                  Competitive compensation &amp; meaningful <br />
                  growth opportunities.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="looking-perks-box">
                <img src="career-img/img4.png" alt="centric tax saving policies logo"/>
                <p>
                  Health and wellness benefits with employee-
                  <br />
                  centric tax saving policies.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="looking-perks-box">
                <img src="career-img/img5.png" alt=" Crystal IT
                  Park logo "/>
                <p>
                  Office facilities at two prime locations in Indore: Crystal IT
                  Park &amp; Pipliyahana.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="looking-perks-box">
                <img src="career-img/img6.jpg" alt="quarterly rewards  logo"/>
                <p>
                  Performance-based appraisals, and quarterly rewards &amp;
                  recognitions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    </>
    )
    }

  export default Section;
    