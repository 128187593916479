import React from "react";
import Gfooter from "../Component/Gfooter";
function Testing() {
  return (
    <>
      <div className="container testing-sec testing-top">
        <h1 className="test-heading">
          <strong>Testing</strong>
        </h1>
        <div className="row testing-data">
          <div className="test-content-sec col-lg-6 col-md-12 col-sm-12">
            <p className="test-para" data-aos="fade-up" data-aos-delay={100}>
              Quality Assurance is imperative to the success of every business
              today which is why more and more enterprises are employing the
              Testing Services. The systems are turning more complex and it has
              become a necessity for an enterprise to look for the best
              solutions that provide high quality applications. It’s advised to
              take the help of professional services offering best quality
              testing services spanning across consulting, functional,
              automation, performance and specialized testing.
            </p>
          </div>
          <div className="test-image col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="../../service-pic/testing.jpg" alt="testing pic" />
          </div>
        </div>
      </div>
      <Gfooter/>
    </>
  );
}

export default Testing;
