import React from "react";
import "./Ipark.css";
import Gfooter from "../Component/Gfooter";

function Iparking() {
  return (
    <div className="parking-top">
      {/* <div className="container-fluid parking-solution">
        <div className="parking-image  col-lg-12 col-md-12 col-sm-12">
          <div className="ipark-banner aos-init aos-animate">
                  <h1 className="text-white">Case Study</h1>
                  <p>Doidunt eget semper nec ruam sed hendrerit morbi ac feliseao augue pellentesue morbi acer.</p>
                  <figure class="ipark-banner-circleright mb-0">
                            <img src="img/sub-bannerleftlayer.png"  alt="ifile layer"/>
                        </figure>
                        <figure class="ipark-banner-circle mb-0">
                            <img src="img/service-rightcircle.png"  alt="ifile circle"/>
                        </figure>
                  <div className="ipark-box">
                    <span className="mb-0 box-home">CaseStudy</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Iparking</span>
                  </div>
                </div>
        </div>
      </div> */}
      <div className="container case3-heading">
        <h1>
          <strong>
            Case Study-3: Implementation Global iParking at Ishanya Mall, Pune.
          </strong>
        </h1>
      </div>

      <div className="container-fluid intro-parking">
        <div className="row ">
          <div className="parking-image3 col-lg-6 col-md-12 col-sm-12">
            <img src="case-study-img/parkintro.jpg" alt="solution pic" />
          </div>

          <div className="parking-content col-lg-6 col-md-12 col-sm-12">
            <h2 className="parking-heading">
              <strong>Introduction</strong>
            </h2>
            <p className="parking-para">
              Parking management in urban areas is a critical challenge,
              especially in busy commercial centers like malls. The introduction
              of Global iParkingat Ishanya Mall in Pune marks a significant step
              forward in efficient parking management. This case study examines
              the implementation, functionality, and impact of Global iParking
              at Ishanya Mall, highlighting its role in enhancing parking
              operations.
            </p>
          </div>
        </div>
      </div>

      <div className="container background-park">
        <h2 className="parkback-heading">
          <strong>Background</strong>
        </h2>
        <p className="park-para">
          Ishanya Mall in Pune is a popular shopping destination, attracting a
          large number of visitors daily. Managing parking efficiently in such a
          high-traffic environment is essential to provide a seamless experience
          for visitors. Traditional parking management methods often lead to
          issues such as congestion, inefficient space utilization, and revenue
          leakage. The need for a more advanced and automated system was clear.
        </p>
      </div>

      <div className="container-fluid park-solution">
        <div className="row">
          <div className="parking-sol col-lg-6 col-md-12 col-sm-12">
            <h2 className="gis-heading">
              <strong>The Global iParking Solution</strong>
            </h2>
            <p className="gis-para">
              Global iParking, based on technology to extract the vehicle
              details from the camera live stream, is designed to address the
              parking management challenges at Ishanya Mall. The system
              leverages live camera feeds, real-time data processing, and
              automated vehicle information capture to optimize parking
              operations. Key features include:
            </p>
            <ul>
              <li>
                <p>
                  <b>Live Camera Feed:</b> Cameras installed at entry and exit
                  points capture real-time footage of vehicles.
                </p>
              </li>
              <li>
                <p>
                  <b>Automated Information Capture:</b> The system records
                  vehicle details such as license plate numbers, vehicle type
                  and timestamps of entry and exit, reducing the need for manual
                  data entry.
                </p>
              </li>
              <li>
                <p>
                  <b>Space Management:</b> The system monitors parking space
                  occupancy and provides real-time information on available
                  spaces.
                </p>
              </li>
              <li>
                <p>
                  <b>High Accuracy:</b> The accuracy rate of 95 to 97% ensures
                  reliable vehicle data capture and processing.
                </p>{" "}
              </li>
            </ul>
          </div>

          <div className="impl-gis col-lg-6 col-md-12 col-sm-12">
            <h2 className="impl-heading">
              <strong>Implementation</strong>
            </h2>
            <p className="impl-para">
              The implementation of Global iParking at Ishanya Mall involved
              several phases:
            </p>
            <ol>
              <li>
                <p>
                  <b>Infrastructure Setup:</b> Installing cameras at all entry
                  and exit points and throughout the parking area to cover all
                  spaces.{" "}
                </p>
              </li>

              <li>
                <p>
                  <b>System Integration:</b> Connecting the camera feeds to the
                  Global iParking software for seamless data processing.
                </p>
              </li>
              <li>
                <p>
                  <b>Training and Testing:</b> Training mall staff to operate
                  and monitor the system, followed by thorough testing to ensure
                  accuracy and reliability.
                </p>
              </li>
              <li>
                <p>
                  <b>Deployment:</b> Full-scale deployment, transitioning from
                  traditional parking management methods to the automated Global
                  iParking system.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container-fluid implgis-info">
        <div className="row">
          <div className="implgis-content col-lg-6 col-md-12 col-sm-12">
            <h2 className="implgis-heading">
              <strong>Impact and Benefits</strong>
            </h2>
            <p className="implgis-para">
              The implementation of Global iParking at Ishanya Mall has resulted
              in numerous benefits:
            </p>
            <ul>
              <li>
                <p>
                  <b>Enhanced Accuracy:</b> With a 95 to 97% accuracy rate, the
                  system ensures precise data capture, reducing errors in
                  vehicle recording and parking fee calculation.
                </p>
              </li>
              <li>
                <p>
                  <b>Improved Efficiency:</b> Automated data capture and
                  real-time space monitoring reduce waiting times for finding
                  parking spots and streamline entry and exit processes.
                </p>
              </li>
              <li>
                <p>
                  <b>Optimal Space Utilization:</b> Real-time information on
                  parking availability helps in better space management,
                  maximizing the utilization of the parking area.
                </p>
              </li>
              <li>
                <p>
                  <b>Increased Revenue:</b>Accurate vehicle data and automated
                  fee calculation ensure proper revenue collection without
                  manual errors or leakages.{" "}
                </p>
              </li>
              <li>
                <p>
                  <b>Enhanced Customer Experience:</b>Reduced congestion and
                  quicker parking processes contribute to a better overall
                  experience for mall visitors.
                </p>
              </li>
              <li>
                <p>
                  <b>Accessibility:</b>Global iParking as a cloud solution
                  available anytime and anywhere through phones, tablets,
                  laptops.
                </p>
              </li>
            </ul>
          </div>

          <div className="chal-solupark col-lg-6 col-md-12 col-sm-12">
            <h2 className="csp-heading">
              <strong>Challenges and Solutions</strong>
            </h2>
            <p className="csp-para">
              The implementation of Global iParking faced several challenges:
            </p>
            <ul>
              <li>
                <p>
                  <b>Technical Integration: </b> Initial technical issues were
                  resolved through regular maintenance and system updates.
                </p>{" "}
              </li>

              <li>
                <p>
                  <b>Staff Training: </b>Comprehensive training programs were
                  conducted to ensure staff proficiency in operating the new
                  system.
                </p>
              </li>
              <li>
                <p>
                  <b>Data Privacy:</b> Measures such as data encryption and
                  secure storage were implemented to protect vehicle
                  information.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid future-prop">
        <div className="row">
          <div className="future-prospectspark col-lg-6 col-md-12 col-sm-12">
            <h2 className="futurepark1-heading">
              <strong>Future Prospects</strong>
            </h2>
            <p className="future-parapark">
              The successful implementation of Global iParking at Ishanya Mall
              sets a benchmark for other commercial centers. Potential future
              enhancements include:
            </p>
            <ul>
              <li>
                <p>
                  <b>Integration with Shopping Rewards Programs:</b> Linking
                  parking data with mall loyalty programs to offer rewards for
                  frequent visitors.
                </p>
              </li>
              <li>
                <p>
                  <b>Advanced Analytics:</b> Using AI and machine learning for
                  predictive analytics to manage peak times and optimize parking
                  operations further.
                </p>
              </li>
              <li>
                <p>
                  <b>Mobile Integration:</b> Developing a mobile app for
                  visitors to check real-time parking availability and reserve
                  spots in advance.
                </p>
              </li>
            </ul>
          </div>

          <div className="park-conclusion col-lg-6 col-md-12 col-sm-12">
            <h2 className="parkc-heading">
              <strong>Conclusion</strong>
            </h2>
            <p className="parkc-para">
              The Global iParking at Ishanya Mall in Pune has revolutionized
              parking management, providing a highly accurate, efficient, and
              customer-friendly solution. The system's ability to automate and
              optimize parking operations significantly enhances the mall's
              operational efficiency and visitor experience. This case study
              demonstrates the potential of advanced technology in transforming
              urban infrastructure management.
            </p>
          </div>
        </div>
      </div>
   <Gfooter/>
    </div>
  );
}

export default Iparking;
