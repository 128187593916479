import React from 'react';
import Gfooter from '../Component/Gfooter';


function BussinessIntelligence() {
    return ( 
      <>
        <div className='container-fluid bussiness-inte bussiness-top'>
        
        <h1 className='bussiness-heading'><strong>Bussiness Intelligence</strong></h1>

        <div className="bussiness-int">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-6" data-aos="zoom-in" data-aos-delay={100}>
                <div className="bussiness-info">
                  <img src="../../service-pic/qlik-2.png" alt="" />
                  <div className="bussiness-content">
                    <div className='bussiness-prof'>
                      <h3 className="heading-bussiness"><strong>Qlikview</strong></h3>
                      <p className="containt-bussiness">
                      Qlikview is a 4th generation Business performance application. Global BI-Tech has built an in-house capability on use of (QlikView) BI & Analytics tool to give customers the flexibility to develop their own analytical applications tailored to their data environments and business requirements. 
                      </p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-6" data-aos="zoom-in" data-aos-delay={100}>
                <div className="bussiness-info">
                  <img src="../../service-pic/tableau.jpeg" alt="" />
                  <div className="bussiness-content">
                  <div className='bussiness-prof'>
                      <h3 className="heading-bussiness"><strong>Tableau</strong></h3>
                      <p className="containt-bussiness">
                      Tableau is a powerful business intelligence and data visualization tool that has a very intuitive user interface. It made the whole decision making process more efficient and better by helping understand the data better, grasp concepts and identify patterns. We provide support for all desktop and server tableau solutions.
                      </p>
                   </div>
                </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-6" data-aos="zoom-in" data-aos-delay={100}>
                <div className="bussiness-info">
                  <img src="../../service-pic/power-bi.jpeg" alt="" />
                  <div className="bussiness-content">
                  <div className='bussiness-prof'>
                    <h3 className="heading-bussiness"><strong>Power Bi</strong></h3>
                      <p className="containt-bussiness">
                      We have team of specialist for Power BI. Power BI is a business analytics service provided by Microsoft. It provides interactive visualizations with self-service business intelligence capabilities, where end users can create reports and dashboards by themselves, without having to depend on information technology staff or database administrators
                      </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Gfooter/>
      </>
     );
}

export default BussinessIntelligence;