import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Carousel() {
  return (
    <div className="container con1">
      <h1>Life At GsunTec</h1>
      <div className="container con2">
        <div id="demo" className="carousel slide" data-bs-ride="carousel">
          {/* <div className="carousel-indicators">
            <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active" />
            <button type="button" data-bs-target="#demo" data-bs-slide-to="1" />
            <button type="button" data-bs-target="#demo" data-bs-slide-to="2" />
            <button type="button" data-bs-target="#demo" data-bs-slide-to="3" />
          </div> */}
          <div className="slider-inner">
            <div className="carousel-item slide2 active">
              
              <img src="career-img/slide-life-1.jpg" alt="Los Angeles" className="d-block  img-fluid" />
              <div className="carousel-caption  d-md-block innerSliderText">
                <h5>Onsite Event</h5>
                <p>
                          We at Gsuntec ensure that by participating in our <br/>
                          Onsite Events, our employees feel committed to the company goals
                          and values and always<br/> feel motivated to contribute to
                          organisational success, whilst at the same <br/>time enhancing
                          their own sense of wellbeing.
                        </p>
              </div>
            </div>
            <div className="carousel-item slide2">
              <img src="career-img/slide-life-2.jpg" alt="Chicago" className="d-block  img-fluid" />
              <div className="carousel-caption  d-md-block innerSliderText">
                <h5>Gsuntec Eve’s</h5>
                <p>
                          A Gsuntec Eve allows employees to collaborate and learn to
                          work <br/>together. It gives an opportunity to exchange ideas and
                          talk to one <br/> another. We bring people together to partake in
                          something everyone can <br/> enjoy - eating delicious food!
                        </p>
              </div>
            </div>
            <div className="carousel-item slide2">
              <img src="career-img/slide-life-3.png" alt="New York" className="d-block  img-fluid" />
              <div className="carousel-caption  d-md-block innerSliderText">
                <h5>Show &amp; Tell</h5>
                <p>
                          We are passionate about what we do, and we strive for <br />
                          holistic development therefore we conduct ted talks each
                          <br /> month to give a golden opportunity to employee to
                          showcase their talent.
                        </p>
              </div>
            </div>
            <div className="carousel-item slide2">
              <img src="career-img/slide-life-4.webp" alt="outings image" className="d-block  img-fluid" />
              <div className="carousel-caption  d-md-block innerSliderText">
                <h5>Outings</h5>
                <p>
                          Lets leave work for a day &amp; move out. Let the brain
                          relax and promote <br/> creativity &amp; collaboration. This will
                          help us effective recharging of <br/> creative cells.
                        </p>
              </div>
            </div>
          </div>
        </div>
        <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#demo"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>

          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#demo"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
      </div>
    </div>
  );
}

export default Carousel;
