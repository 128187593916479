import React from "react";
function Slider() {
  return (
    <section>
      <div className="container-fluid px-0" >
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel" data-bs-interval="1000">
          <div className="carousel-inner" role="listbox" >
            <div className="carousel-item active">
              <img src="project-pic/software.jpg" className="img-fluid" alt="First slide" />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h1 className="text-white display-1 animated fadeInUp">
                    Software Engineering
                  </h1>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">
                    Rock-solid solution for a changing world.
                  </p>
                  <a href="/" className="me-2">
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      LEARN MORE
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <img
                src="project-pic/bi.jpg"
                className="img-fluid"
                alt="Second slide"
              />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h1 className="text-white display-1 mb-4 animated fadeInUp">
                    Business Intelligence
                  </h1>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">
                    Informed Intelligence on demand, Anywhere, EveryWhere
                  </p>
                  <a href="/" className="me-2">
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      LEARN MORE
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <img src="project-pic/predictive.jpg" className="img-fluid" alt="Third slide" />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h1 className="text-white display-1 mb-4 animated fadeInUp">
                    Predictive Analytics
                  </h1>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">
                    GSTS offers Prophecy, our flagship product for Predictive
                    Analytics.
                  </p>
                  <a href="/" className="me-2">
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      LEARN MORE
                    </button>
                    
                  </a>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <img
                src="project-pic/modernization.jpg"
                className="img-fluid"
                alt="Fourth slide"
              />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h1 className="text-white display-1 mb-4 animated fadeInUp">
                    Modernization
                  </h1>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">
                    Evolve, Innovate and dominate your market
                  </p>
                  <a href="/" className="me-2">
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      LEARN MORE
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
  );
}

export default Slider;
