import React from "react";
import "./ProductNew.css";
import { Link } from "react-router-dom";

function ProductNew() {
  return (
    <>
      <section className="service-section">
        <figure className="service-leftlayer mb-0">
          <img
            src="product-img/service-leftlayer.png"
            alt=""
            className="img-fluid"
          />
        </figure>
        <figure className="service-dotimage mb-0">
          <img
            src="product-img/service-dotimage.png"
            alt=""
            className="img-fluid"
          />
        </figure>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="service_contentbox">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="service-box box-mb">
                      <figure className="service-marketicon">
                        <img
                          src="product-img/crane.png"
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                      <h4>Global Port Management System</h4>
                      <p className="text-size-18">
                        Managing terminal operations in the maritime industry
                        demands efficiency.
                      </p>
                    <Link to="/product-page">
                      <i className="circle fa-thin fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="box-top">
                      <div className="service-box box-mb">
                        <figure className="service-producticon">
                          <img
                            src="product-img/parking.png"
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                        <h4>Global iParking Management System</h4>
                        <p className="text-size-18">
                          Parking management in urban areas and commercial
                          centers presents significant challenges in terms of
                          efficiency.
                        </p>

                        <Link to="/product-page">
                      <i className="circle fa-thin fa-arrow-right" />
                      </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="service-box">
                      <figure className="service-designicon">
                        <img
                          src="product-img/barrier.png"
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                      <h4>Global Toll Plaza Management System</h4>
                      <p className="text-size-18">
                        Efficient toll plaza management is crucial for ensuring
                        smooth traffic flow.
                      </p>

                      <Link to="/product-page">
                      <i className="circle fa-thin fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="box-top">
                      <div className="service-box">
                        <figure className="service-dataicon">
                          <img
                            src="product-img/computer.png"
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                        <h4>Global iFile Management System</h4>
                        <p className="text-size-18">
                          Efficient document management is critical for
                          organizational productivity and compliance.
                        </p>

                        <Link to="/product-page">
                      <i className="circle fa-thin fa-arrow-right" />
                      </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div
                className="service_content position-relative aos-init aos-animate"
                data-aos="fade-right"
              >
                <figure className="service-rightcircle mb-0">
                  <img
                    src="product-img/service-rightcircle.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <h3 className="our-products-heading"><strong>Our Products</strong></h3>
                <h2>BEST IT SOLUTION FOR YOUR BUSINESS</h2>
                <p>
                  To orient your reader and emphasize your company's services,
                  start by introducing the name. Then, immediately include your
                  business's service or product so that your reader can
                  immediately identify what your company actually does.
                </p>
                <h4>Our Products:-</h4>
                <ul className="list-unstyled mb-0">
                  <li className="text">
                    <i className="circle fa-duotone fa-check" />
                    Global Port Management System.
                  </li>
                  <li className="text">
                    <i className="circle fa-duotone fa-check" />
                    Global iParking Management System.
                  </li>
                  <li className="text">
                    <i className="circle fa-duotone fa-check" />
                    Global Toll Plaza Management System.
                  </li>
                  <li className="text text1">
                    <i className="circle fa-duotone fa-check" />
                    Global iFile Management System
                  </li>
                </ul>
                <div className="get_started text-white">
                  <Link to="/product-page">Read More</Link>
                  <figure className="mb-0">
                    <img
                      src="product-img/button-arrow.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <figure className="service-rightlayer mb-0">
          <img
            src="product-img/service-rightlayer.png"
            alt=""
            className="img-fluid"
          />
        </figure>
      </section>
    </>
  );
}

export default ProductNew;
