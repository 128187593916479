import React from "react";
import "./Ifile.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Gfooter from "../Component/Gfooter";

AOS.init();

function IfileSolution() {
  return (
    <div className="ifile-top">
      <div className="container-fluid ifile-solution">
        <div className="ifile-image  col-lg-12 col-md-12 col-sm-12" >
         <div className="ifile-banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Case Study</h1>
                  <p>"Empower your business with tailored solutions that drive efficiency, growth, and success."</p>
                  <figure class="ifile-banner-circleright mb-0">
                            <img src="img/sub-bannerleftlayer.png"  alt="ifile layer"/>
                        </figure>
                        <figure class="ifile-banner-circle mb-0">
                            <img src="img/service-rightcircle.png"  alt="ifile circle"/>
                        </figure>
                  <div className="ifile-box">
                    <span className="mb-0 box-home">Case Study</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Ifile</span>
                  </div>
                </div>


          {/* <div className="company-overview">
            <h3 className="company-heading">
              <strong>Company Overview</strong>
            </h3>

            <ul>
              <li>
                <b>Industry:</b> Car Dealership Company{" "}
              </li>
              <li>
                <b>Location:</b> Nagpur, MH, India
              </li>
              <li>
                <b>Employees:</b> 100 professionals, 30 support staff{" "}
              </li>
              <li>
                <b>Annual Revenue:</b> $15 million{" "}
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      <div className="container case1-heading">
        <h1>
          <strong>
            Case Study-1: Implementing Global iFile Solutions in a Car
            Dealership Company.
          </strong>
        </h1>
      </div>

      <div className="container-fluid background">
        <div className="row ">
          <div className="case-image col-lg-6 col-md-12 col-sm-12">
            <img src="case-study-img/ifilecase2.jpg" alt="solution pic" />
          </div>
          <div className="background-content col-lg-6 col-md-12 col-sm-12">
            <h2 className="background-heading">
              <strong>Background</strong>
            </h2>
            <p className="background-para">
              This is been implemented is a mid-sized company offers a One-Stop
              Solution for all its customer needs with Sales, Service & Spares
              available under one roof for car sells & services. Apart from
              basic sales services like Enquiries to Deliveries of vehicles,
              customer can also avail the facilities of Vehicle Finance,
              Insurance & Exchange too., dealing with complex filings and a
              significant volume of paperwork. Historically, the firm relied on
              manual processes and traditional software for e-filing, leading to
              inefficiencies, human errors, and extended processing times.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid challenge-info">
        <div className="row">
          <div className="challenge col-lg-6 col-md-12 col-sm-12" >
            <h2 className="challenge-heading">
              <strong>Challenge</strong>
            </h2>
            <ul>
              <li>
                <b>Efficiency:</b> Manual data entry and review were
                time-consuming, slowing down the e-filing process.
              </li>
              <li>
                <b>Accuracy:</b> Human errors in data entry and calculations led
                to corrections and delays.
              </li>
              <li>
                <b>Scalability:</b> During peak season, the firm struggled to
                manage the high volume of filings.
              </li>
              <li>
                <b>Compliance:</b> Ensuring compliance with the latest
                regulations and standards was labor-intensive.
              </li>
            </ul>
          </div>

          <div className="car-solution col-lg-6 col-md-12 col-sm-12">
            <h2 className="solution-heading">
              <strong>Solution</strong>
            </h2>
            <p className="ifile-para">
              Car Dealership company decided to implement an AI-driven Global
              iFilesolution to address these challenges. The iFilesystem was
              designed to automate data entry, enhance accuracy, and streamline
              the entire filing process.
            </p>
          </div>
        </div>
      </div>

      <div className="container ifile-feature">
        <h2 className="feature-heading">
          <strong>Features of the Global iFile Solution</strong>
        </h2>
        <ol>
          <li>
            <b>Automated Data Extraction:</b> AI algorithms extract data from
            documents, forms, and receipts, reducing manual entry.
          </li>
          <li>
            <b>Error Detection:</b> Machine learning models identify and flag
            potential errors and inconsistencies.
          </li>
          <li>
            <b>Scalability:</b> Cloud-based infrastructure allows the firm to
            handle increased workloads during peak seasons.
          </li>
          <li>
            <b>User-Friendly Interface:</b> An intuitive interface for tax
            professionals to review and approve filings.
          </li>
        </ol>
      </div>
      <div className="container-fluid process">
        <div className="row">
          <div className="imple-process col-lg-6 col-md-12 col-sm-12">
            <h2 className="process-heading">
              <strong>Implementation Process</strong>
            </h2>
            <ol>
              <li>
                <b>Needs Assessment:</b> A thorough assessment was conducted to
                understand the firm's specific requirements and pain points.
              </li>
              <li>
                <b>Vendor Selection:</b> After evaluating several vendors, Car
                Dealership company chose an Global iFile provider with a proven
                track record in the industry.
              </li>
              <li>
                <b>Pilot Testing:</b> The AI solution was initially deployed in
                a pilot program, processing a limited number of tax returns to
                test its functionality and reliability.
              </li>
              <li>
                <b>Training:</b> Comprehensive training sessions were conducted
                for tax professionals and support staff to ensure they were
                proficient in using the new system.
              </li>
              <li>
                <b>Full Deployment:</b> Following successful pilot testing, the
                Global iFile solution was fully deployed across the firm.
              </li>
            </ol>
          </div>

          <div className="result col-lg-6 col-md-12 col-sm-12">
            <h2 className="result-heading">
              <strong>Results</strong>
            </h2>
            <ul>
              <li>
                <b>Efficiency:</b> The time required to process and sharing
                documents was reduced by 70%, enabling the firm to handle more
                clients and meet deadlines more effectively.
              </li>
              <li>
                <b>Accuracy:</b> The AI system significantly reduced the error
                rate in documentation, decreasing the need for corrections and
                amendments.
              </li>
              <li>
                <b>Scalability:</b> The firm seamlessly managed the increased
                volume of documents during the peak seasons without additional
                staffing.
              </li>
              <li>
                <b>Compliance:</b> The AI system ensured that all documents were
                compliant, reducing the risk of penalties and fines.
              </li>
              <li>
                <b>Employee Satisfaction:</b> Employess reported higher job
                satisfaction due to reduced repetitive tasks and more focus on
                complex and rewarding aspects of document preparation.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container conclusion">
        <h2 className="conclusion-heading">
          <strong>Conclusion</strong>
        </h2>
        <p className="conclusion-para">
          Implementing the Global iFile solution transformed Unnati Motors'
          operations, enhancing efficiency, accuracy, and compliance while
          providing a scalable solution to manage peak season demands. The
          success of this initiative positions Unnati Motors as a leader in
          leveraging advanced technology in the automobile industry.
        </p>
      </div>
<Gfooter/>
    </div>
  );
}

export default IfileSolution;
