import React from "react";
import { Link } from "react-router-dom";
import Gfooter from "../Component/Gfooter";

function Project1() {
  return (
    <>
     <div className="container-fluid project-bg ">
      <div className="col-lg-12 col-md-12 col-sm-12">
          <img src="career-img/project1bg.jpg" alt="  Python Developer"/>
        </div>
        </div>
        <div className="container-fluid job-details">
      <div className="row">
          <div className="col-lg-12 col-md-10 col-sm-12">
              <h1 className="job-project-heading"><strong>Python Developer</strong></h1>
          </div>
        </div>

        <div className="max-w-1200 mx-auto">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-7 col-sm-12 pr-50 mb-5">
              <div className="job-description-container ql-editor">
                <h2>Requirements :</h2>
                <p>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    Preferably candidate having 2-5 years.
                    <p className="set-data">
                      <ul>
                        <li>
                          * Good insight into Python, Pytest, Core Java and
                          related frameworks.
                        </li>
                        <li>
                          * Experience in maintaining Continuous Integration
                          Environments e.g. using Jenkins, Gradle, Maven, etc.
                        </li>
                        <li>
                          * Familiar with source control systems like GIT,
                          subversion, Perforce, etc.
                        </li>
                        <li>
                          * Capable of taking end to end ownership of user
                          stories (features) Design, Implementation, Test
                          automation, etc.
                        </li>
                        <li>
                          * Understands the system requirements and able to come
                          out with an optimum Architecture for complex features
                          (Mandatory skill for Senior folks).
                        </li>
                        <li>
                          * Good Team Player and experience in working with
                          remote Teams.
                        </li>
                        <li>* Good verbal and written communication skills.</li>
                      </ul>
                    </p>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="sticky-job">
                <div className="btn-container col-md-9 col-sm-12">
                  <button className="btn btn-info" id="apply-job">
                    <Link to="/form">
                      Apply for this job{" "}
                      <span className="arrow-icon">
                        <i className="fa-solid fa-arrow-right-long" />
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<Gfooter/>
    </>
  );
}

export default Project1;
