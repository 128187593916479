import React from 'react'
import './Casestudy.css';
// import Portfolio from './Portfolio';
import Box from './Box';
import Button from './Button';
import Gfooter from '../Component/Gfooter';
import Casestudy4 from './Casestudy4';


function CaseStudy() {
    return ( 
        <>
        {/* <div className='container-fluid case-study-banner-top'>
                  <div className="case-study-image col-lg-12 col-md-12 col-sm-12">
         <img src="about-img/Artboard 5.jpg" alt="case study banner"/>
         <div className="case-study-banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Case Study</h1>
                  <p>"Explore how we turned challenges into success with our proven strategies in this detailed case study."</p>
                  <div className="case-study-box">
                    <span className="mb-0 box-home">Home</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Case Study</span>
                  </div>
                </div>
         </div>
         </div> */}
        <Box/>
        {/* <Casestudy4/> */}
        {/* <Portfolio/> */}
        <Button/>
<Gfooter/>
        </>
     );
}

export default CaseStudy;


