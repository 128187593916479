import React from "react";
import "./Gfooter.css";
import { Link } from "react-router-dom";
import $ from "jquery";

function Gfooter() {
  $(document).ready(function () {
    var $scrollToTop = $("#scrollToTop");

    // Handle scroll event
    $(window).scroll(function () {
      // Show or hide the scroll-to-top button based on scroll position
      if ($(this).scrollTop() > 250) {
        $scrollToTop.fadeIn("fast"); // Use "fast" for quicker visibility
      } else {
        $scrollToTop.fadeOut("fast"); // Use "fast" for quicker hiding
      }
    });

    // Scroll to top when the button is clicked
    $scrollToTop.click(function () {
      $("html, body").animate({ scrollTop: 0 }, 2); // Use "fast" for quicker scrolling
      return false; // Prevent default action
    });
  });

  return (
    <footer>
      <div className="footer-section">
        <div className="container box1">
          <div className=" box2 ">
            <div className="row">
              {/* <div className="col-lg-4 col-md-4 col-sm-12 footer-data">
                <h4>Services</h4>
                <ul>
                  <li>
                    <Link to="/services/mobile-solutions">Mobile Solution</Link>
                  </li>
                  <li>
                    <Link to="/services/testing">Testing</Link>
                  </li>
                  <li>
                    <Link to="/services/enterprise-solutions">
                      Enterprices Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/business-intelligence">
                      Business Intelligence
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/data-science">Data Science</Link>
                  </li>
                </ul>
              </div> */}

              <div className="col-lg-6 col-md-6 col-sm-12 footer-data">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 size3">
                <h4 className="addr-head">Our Head Office</h4>

                <p>
                Global Suntec Solutions Pvt. Ltd<br />
              E6 Laxmipuram,<br />
              Infront Of Commerce Zone IT Park<br />
              Pune 411006 MH, INDIA
                </p>
              </div>

              {/* <div className="row city-icons">
                <div className="col-lg-2 col-md-2 col-sm-12 city-icon ">
                  <img src="../../img/india.svg" alt="india" />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 city-icon">
                  <h6>India</h6>
                  <p>Indore</p>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 city-icon">
                  <img src="../../img/singapore.png" alt="singapore" />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 city-icon">
                  <h6>Singapore</h6>
                  <p>Bishan</p>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 city-icon">
                  <img src="../../img/austria.png" alt="austria" />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 city-icon">
                  <h6>Austria</h6>
                  <p> Vienna</p>
                </div>
              </div> */}


              
              <div className="border_line"></div>
              <div className="row ">
                <div className="col-lg-6 col-md-6 col-sm-12 policy-rights">
                  <p>
                    ©<span> Global SunTec</span> Solutions 2024 — All Rights
                    Reserved.
                  </p>
                </div>
                
                <div className="col-lg-2 col-md-2 col-sm-12 policy-rights">
                  <span>
                    <Link to="/privacy">Privacy policy</Link>
                  </span>
                </div>
                <div className="col-lg-2 col-md-1 col-sm-12"></div>
                <div className="col-lg-2 col-md-3 col-sm-12 social-icons">
                  <ul>
                    <li>
                      <a
                       href="https://www.facebook.com/gsuntec2023----"
                        class="facebook-icon"
                        target="_blank"
                      >
                        <svg
                          width={12}
                          height={22}
                          viewBox="0 0 12 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.73264 3.93002C9.66946 3.93002 11.2504 3.93002 11.2504 3.93002V0.71875C11.2504 0.71875 10.1379 0.71875 8.67408 0.71875C7.21029 0.71875 3.63864 0.835524 3.63864 5.03936V7.72515H0.886719V11.0532H3.63864V21.446H7.62015V11.1116H10.8405L11.2504 7.78354H7.62015V5.09775C7.62015 5.09775 7.79581 3.93002 8.73264 3.93002Z"
                            // fill="#4267B2"
                            fill="#ffffff"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/gsuntec_2024?igsh=YzljYTk1ODg3Zg==" target="_blank">
                        <svg
                          width={21}
                          height={21}
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.98796 6.65751C0.98796 5.41116 1.21326 4.1648 1.88915 3.0884C2.56504 2.06866 3.46623 1.44548 4.59271 1.04891C5.71919 0.652344 6.84567 0.652344 7.97215 0.652344C10.1688 0.652344 12.3654 0.652344 14.5621 0.652344C15.8012 0.652344 16.984 0.935606 17.9978 1.61544C19.1806 2.40857 19.9129 3.54162 20.1382 4.90128C20.3071 5.69442 20.3635 6.54421 20.3635 7.39399C20.3635 9.7734 20.3635 12.1528 20.3071 14.5322C20.3071 15.552 20.0818 16.5717 19.5186 17.4781C18.8427 18.5545 17.9415 19.291 16.7024 19.6876C15.5759 20.0275 14.4494 20.0842 13.3229 20.0842C11.1826 20.0842 8.98599 20.0842 6.84567 20.0842C5.71919 20.0842 4.5927 19.8575 3.63519 19.3477C2.45238 18.6678 1.72017 17.7048 1.26958 16.4017C0.98796 15.552 0.931641 14.6455 0.931641 13.7391C0.987965 13.5691 0.931635 8.47039 0.98796 6.65751ZM2.84666 15.1554C2.95931 16.0618 3.35358 16.9116 4.14211 17.5348C4.93065 18.1013 5.77551 18.3279 6.73302 18.3279C9.66187 18.3846 12.5344 18.4979 15.4633 18.2146C16.1391 18.158 16.7024 17.9314 17.2093 17.5348C18.2231 16.7983 18.5611 15.7219 18.5611 14.5322C18.6174 12.3228 18.6174 10.17 18.6174 7.96052C18.6174 7.16738 18.5611 6.37425 18.5048 5.63777C18.3921 4.78798 18.0542 4.0515 17.4346 3.42832C16.7024 2.80514 15.8575 2.52188 14.9 2.52188C12.5344 2.46522 10.1688 2.46522 7.80318 2.46522C6.95832 2.46522 6.05713 2.46522 5.21227 2.74849C3.74784 3.20171 3.01563 4.33476 2.90298 5.69442C2.73401 7.33734 2.73401 14.0223 2.84666 15.1554Z"
                            // fill="url(#paint0_linear_1579_3498)"
                            fill="#ffffff"
                          />
                          <path
                            d="M10.6755 5.41092C13.379 5.41092 15.6883 7.62037 15.632 10.453C15.632 13.2856 13.2664 15.4384 10.6191 15.4384C7.85925 15.4384 5.60629 13.1723 5.66261 10.3963C5.71893 7.56372 7.91557 5.35427 10.6755 5.41092ZM13.8859 10.453C13.9423 8.75342 12.4778 7.16715 10.6755 7.16715C8.92941 7.16715 7.46498 8.64012 7.46498 10.3397C7.46498 12.1526 8.87308 13.6255 10.7318 13.6255C12.4215 13.6255 13.8859 12.1526 13.8859 10.453Z"
                            // fill="url(#paint1_linear_1579_3498)"
                            fill="#ffffff"
                          />
                          <path
                            d="M15.8574 6.37515C15.2379 6.4318 14.6746 5.86528 14.6746 5.2421C14.6746 4.61892 15.2379 4.0524 15.8574 4.0524C16.5333 4.0524 17.0402 4.61892 17.0402 5.2421C17.0402 5.80862 16.5333 6.4318 15.8574 6.37515Z"
                            // fill="url(#paint2_linear_1579_3498)"
                            fill="#ffffff"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1579_3498"
                              x1="18.6017"
                              y1="2.0195"
                              x2="2.19605"
                              y2="18.33"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#8A3AB9" />
                              <stop offset="0.34375" stopColor="#CD486B" />
                              <stop offset="0.6875" stopColor="#FBAD50" />
                              <stop offset={1} stopColor="#FCCC63" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_1579_3498"
                              x1="18.6017"
                              y1="2.0195"
                              x2="2.19605"
                              y2="18.33"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#8A3AB9" />
                              <stop offset="0.34375" stopColor="#CD486B" />
                              <stop offset="0.6875" stopColor="#FBAD50" />
                              <stop offset={1} stopColor="#FCCC63" />
                            </linearGradient>
                            <linearGradient
                              id="paint2_linear_1579_3498"
                              x1="18.6017"
                              y1="2.0195"
                              x2="2.19605"
                              y2="18.33"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#8A3AB9" />
                              <stop offset="0.34375" stopColor="#CD486B" />
                              <stop offset="0.6875" stopColor="#FBAD50" />
                              <stop offset={1} stopColor="#FCCC63" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/global-suntec-solutions/posts/?feedView=all" target="_blank">
                        <svg
                          width={19}
                          height={18}
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.96569 0.605469C1.80937 0.605469 0.863281 1.5517 0.863281 2.70821C0.863281 3.86471 1.80937 4.81095 2.96569 4.81095C4.12202 4.81095 5.0681 3.86471 5.0681 2.70821C5.01554 1.5517 4.12202 0.605469 2.96569 0.605469Z"
                            // fill="#0077B5"
                            fill="#ffffff"
                          />
                          <path
                            d="M4.86046 6.17578H1.07617V17.6883H4.86046V6.17578Z"
                            // fill="#0077B5"
                            fill="#ffffff"
                          />
                          <path
                            d="M13.8419 5.91406C12.3703 5.91406 11.3717 6.59745 10.6358 7.49112V6.1769H6.85156V17.6894H10.6358V10.9606C10.7409 9.48872 11.7921 9.12074 12.528 9.12074C13.3163 9.12074 14.2624 9.48872 14.2624 11.5915V17.6894H18.0467V11.5915V9.54129C18.0467 9.59386 17.8364 5.91406 13.8419 5.91406Z"
                            // fill="#0077B5"
                            fill="#ffffff"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <figure className="mb-0 footer-rightcircle">
            <img
              src="../../about-img/about-rightcircle.png"
              alt="right circle"
            />
          </figure> */}
          <figure className="mb-0 banner-leftlayer">
            <img src="../../img/banner-layerright.png" alt="right layer" />
          </figure>
        </div>
      <div id="scrollToTop" className="scroll-top">
        <img
          loading="lazy"
          src="../../img/up-arrow.png"
          alt="Back to Top"
          title="Back to Top"
        />
      </div>
      </div>
    </footer>
  );
}

export default Gfooter;
