import React from 'react'
import { Link } from 'react-router-dom'
function ButtonBlog() {
  return (
    <>
      <div className="container content dark-text">
        <div className="row">
          <div className=" col-lg-12 col-md-12 col-md-12 flex-wrap" data-aos="fade-up" data-aos-delay={40}>
            <div className="spacer30 reduceMobile" />
            <div className="filter-button-group" data-aos="fade-up" data-aos-delay={1000}>
              <button data-filter="*" className="blog-btn">All</button>
              <button data-filter=".automation" className="blog-btn ">APP DEVELOPMENT</button>
              <button data-filter=".b2b-solutions" className="blog-btn ">ARTIFICIAL INTELLIGENCE</button>
              <button data-filter=".e-commerce" className="blog-btn ">BLOCKCHAIN</button>
              <button data-filter=".enterprise-mobility" className="blog-btn ">CLOUD</button>
              <button data-filter=".entertainment" className="blog-btn ">DATA ENGINEERING</button>
              <button data-filter=".fintech" className="blog-btn">DEVOPS</button>
              <button data-filter=".health-fitness" className="blog-btn ">DIGITAL MARKETING</button>
              <button data-filter=".igaming-and-fantasy-sports" className="blog-btn">FINTECH</button>
              <button data-filter=".iot" className="blog-btn">IoT</button>
              <button data-filter=".machine-learning" className="blog-btn ">GENERATIVE AI</button>
              <button data-filter=".music-entertainment" className="blog-btn">LEARNING MANAGEMENT SYSTEM</button>
              <button data-filter=".real-estate" className="blog-btn ">MACHINE LEARNING</button>
              <button data-filter=".shopping-app" className="blog-btn ">MOBILE APPS</button>
              <button data-filter=".smart-city" className="blog-btn ">NEWS</button>
              <button data-filter=".social-networking" className="blog-btn ">NFT</button>
              <button data-filter=".specialities-and-services" className="blog-btn ">PYTHON</button>
              <button data-filter=".travel-hospitality" className="blog-btn">SOFTWARE DEVELOPMENT</button>
              <button data-filter=".travel-hospitality" className="blog-btn">START UP</button>
              <button data-filter=".travel-hospitality" className="blog-btn">TECHNOLOGY</button>
              <button data-filter=".travel-hospitality" className="blog-btn">WEB3</button>
              <button data-filter=".travel-hospitality" className="blog-btn">WEBS APPS</button>
              <button data-filter=".travel-hospitality" className="blog-btn">ZOHO</button>
            </div>
          </div>
        </div>
      </div>


      <div className='grid showpost aos-init aos-animate' data-aos="fade-up" data-aos-delay="100">
        <div className='container box3'>
          <Link to="#" className='blog-item white bg mbottom50 element-item blockchain'>
            <div className='box11 row'>
              <div className='col-md-2 column-1 text-center right-border vertical-center center'>
                <span className='date-month'>July<br /></span>
                <span className='date-day'>4</span>
                <span className='vertical-line'></span>
              </div>
              <div className='col-md-5 column-2 white-bg vertical-center column-2-mob'>
                <h3>Tokenisation: Transforming Assets into Digital Token &nbsp;</h3>
              </div>
              <div className='col-md-5 pl-sm-0'>
                <div className='blog-item-image'>
                  <img src="blog-img/img7.jpg" className='box10' alt="Tokenisation:Transforming Assets into Digital Token &nbsp;" />
                </div>
              </div>
            </div>
          </Link>
        </div>

      </div>

    </>

  );
}

export default ButtonBlog;