import React from "react";
import "./whychooseus.css";
import {Link} from 'react-router-dom';
function Whyus() {
  return (
    <>
      <section className="choose-us-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 choose-col">
              <div className="why_us_wrapper">
                <figure className="mb-0 whyus_img">
                  <img src="about-img/chooseus1.jpg" alt="" />
                </figure>
                <figure className="mb-0 whyus-dot">
                  <img
                    src="about-img/about-dot.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 choose-col">
              <div className="whyus_content aos-init" data-aos="fade-right">
                <h2>Why Choose Us..<b>.?</b></h2>
                <p>Choosing Global SunTec Solutions means partnering with a leader in technology innovation and excellence. Here’s why businesses around the world trust us:</p>
                <p>
                <ul>
                <li>
                
                <b>Innovative Solutions:</b> We are at the forefront of technological advancements, offering cutting-edge products and services that drive efficiency and growth.         
              </li>
                <li>
                
                  <b>Customer-Centric Approach :</b> Our
                  clients are at the heart of everything we do. We listen,
                  understand, and deliver solutions tailored to your unique
                  needs.               
                </li>
                <li>                  
                   <b>Expert Team :</b> Our team of skilled professionals brings
                  extensive experience and expertise, ensuring high-quality
                  results and seamless project execution.                 
                </li>
                <li> 
                  <b>Comprehensive Services :</b> From software development and IT consulting to
                  system integration, we offer a full spectrum of services to
                  meet your business requirements.                  
                </li>
                              
                </ul>
                </p>
                <Link to="/about"
                  className="read_more text-white text-decoration-none">
                  Read More                 
                    <i className="arrow fa-solid fa-arrow-right" style={{color: '#ffffff', position: 'relative', right: '10px', top: '2px'}}></i>
                  
                  
                </Link>
              </div>
            </div>
          </div>
        </div>
    

        {/* <figure className="mb-0 about-rightcircle">
          <img
            src="about-img/about-rightcircle.png"
            alt=""
            className="img-fluid"
          />
        </figure> */}
      </section>
    </>
  );
}

export default Whyus;
