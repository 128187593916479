import React from 'react';

function Recognised() {
    return (  
    <div className='container block-rec'>
      <div className="apd-company-wrap text-center">
        <div className="lead-container container">
          <h3 className="text">Recognised By World's Best For Building A Better <br/> Workplace.</h3>
          <div className="img-section">
            <ul className="row recognize_logo" data-aos="fade-down" data-aos-delay={60}>
              <li className='col-lg-3 col-md-3 col-sm-12'>
                <img
                  style={{height: 80, width: 50}}
                  src="career-img/gptw1.png"
                  alt="Great Place To Work"
                  />
              </li>
              <li className='col-lg-3 col-md-3 col-sm-12'>
                <img
                style={{height: 70, width: 150}}
                  src="career-img/ceo.png"
                  height="67"
                  className="heightList"
                  alt="CEO Orientation"
                  />
              </li>
              <li className='col-lg-3 col-md-3 col-sm-12'>
                <img
                  src="career-img/award.png"
                  alt="Careers"
                />
              </li>
              <li className='col-lg-3 col-md-3 col-sm-12'>
                <img
                style={{height: 80, width: 150}}
                  src="career-img/aon.png"
                  className="heightList"
                  alt="AON Hewitt"
                  />
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
    );
}
export default Recognised;