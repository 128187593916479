import React from "react";
import "./FocusPage.css";
function FocusPage() {
  return (
    <div className="top-focus">
    <div className="container focus-table">
      <h1><strong>We Focus On 3 Things</strong></h1>
    <p>
     Our mission is rather simple; Global Suntec is committed to help its
     customers with their goal and driving ambition to be the trusted
     partner of their choice, delivering reliable, complete floating
     production solutions that create value for our clients, by sustainably
     and passionately leveraging the Company's technology and operating
     experience.
   </p>
    </div>
      <div className="container technology-sec">
        <h1 className="technology-heading">
          <strong>Technology</strong>
        </h1>
        <div className="row technology-data for-effect">
        <div className="technology-img col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="service-pic/technology_2.jpg" alt="technology" />
          </div>
          <div className="technology-content-sec col-lg-6 col-md-12 col-sm-12">
            <p className="technology-para" data-aos="fade-up" data-aos-delay={100}>
             For Automation, Productivity And Convenience Throughout All
             Industries- Consumer And Business-To-Business. Technology Has
             Greater Impact Effects On Business Operations. Technology Helps
             Current Challenges.No Matter The Size Of Organization, Technology
             Has Both Tangible And Intangible Benefits. We Provide Customers
             Our Expertise And Experiences To Achieve Goals And Aspirations
            </p>
          </div>
         
        </div>
      </div>


      <div className="container technology-sec">
        <h1 className="technology-heading">
          <strong>Methodology</strong>
        </h1>
        <div className="row technology-data for-common-effect">
          <div className="technology-content-sec col-lg-6 col-md-12 col-sm-12">
            <p className="technology-para" data-aos="fade-up" data-aos-delay={100}>
             Selecting Right Methodology Is The Crucial To Getting The Job
             Right. The Best Set Of Processes For Your Organization Might Be A
             Combination Of Frameworks And Methodologies Rather Than Just One.
             Our Clients Depend On Our Ability To Deliver Based On Their
             Business Processes And Domain. Right Methology and software
             solution is necessary for streamlining work process, improving
             business productivity, cutting down costs and solving other major
             business tasks.
            </p>
          </div>
          <div className="technology-img col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="service-pic/methodology.jpg" alt="methodology" />
          </div>
         </div>
      </div>


      <div className="container technology-sec">
        <h1 className="technology-heading">
          <strong>Business Challenges</strong>
        </h1>
        
        <div className="row technology-data for-effect">
        <div className="technology-img col-lg-6 col-md-12 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
            <img src="service-pic/businesschallenges.jpg" alt="business challenges" />
          </div>
          <div className="technology-content-sec col-lg-6 col-md-12 col-sm-12">
            <p className="technology-para" data-aos="fade-up" data-aos-delay={100}>
             The Complexity Of A Business Book Goes Up With Each Business. We
             Live In Rapidly Changing Times, Especially For Businesses. We Are
             Leading Software Company Providing Smart Solutions To Address Your
             Business Challenges. We Go Above And Beyond To Help Client Achieve
             their Business Objectives And Overcome Business Challaenges. Our
             Experience And Experise Is The Key To Our Objectivity, And Allows
             Our Experts To Provide Best Technical Solutions That Clients Can
             Trust.
            </p>
          </div>
         
        </div>
      </div>


    </div>
  );
}

export default FocusPage;
