import React from "react";
import {Link} from 'react-router-dom';
function Box() {
  return (
    <div className="container">
    <div className="row case-study-top">
      <div className="element-item  automation b2b-solutions  angular-js innovation python web-apps col-lg-4 col-md-6 col-sm-12" data-aos="fade-right" data-aos-delay={100}>
        <Link to="/Ifile"
          className="white-bg portfolio-item blue"
        >
          <h3>Global iFile Management system</h3>
          <p>
            <span>
            Case Study-1: <br/>Global iFile Management System - Revolutionizing File Management with AI
            </span>
          </p>
          <p className="text-right mb-0">
            <span>Read More{" "}
            <img
              src="case-study-img/slide-next-pure-white.png"
              alt="Link Arrow"
            />
            </span>
          </p>
        </Link>
      </div>

      <div className="element-item1  automation b2b-solutions  angular-js innovation python web-apps col-lg-4 col-md-6 col-sm-12" data-aos="fade-down" data-aos-delay={40}>
          <Link to="/plaza"
            className="white-bg portfolio-item orange"
          >
            <h3>Global Toll Plaza Management system</h3>
            <p>
              <span>
              Case Study-2: Implementation of Global TPMS at a Toll Plaza, Nagpur.
              </span>
            </p>
            <p className=" text-right mb-0">
            <span>Read More{" "}
            <img
              src="case-study-img/slide-next-pure-white.png"
              alt="Link Arrow"
            />
            </span>
            </p>
          </Link>
        </div>


        
      <div className="element-item  automation b2b-solutions  angular-js innovation python web-apps col-lg-4 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay={100}>
          <Link to="/parking"
            className="white-bg portfolio-item blue"
          >
            <h3>Global iParking Management system</h3>
            <p>
              <span>
              Case Study-3: Implementation Global iParking at Ishanya Mall, Pune.
              </span>
            </p>
            <p className=" text-right mb-0">
            <span>Read More{" "}
            <img
              src="case-study-img/slide-next-pure-white.png"
              alt="Link Arrow"
            />
            </span>
            </p>
          </Link>
        </div>
    </div>
    </div>
  );
}

export default Box;
