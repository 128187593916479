import React from "react";
import "./About.css";
import AboutCard from "./AboutCard";
import Gfooter from "../Component/Gfooter";
import AboutNewRead from "./AboutNewRead";
import WhyChooseNew from "./WhyChooseNew";
import Recognised from "../career/Recognised";
import ClientSpeackData from "../Component/ClientSpeackData";

function About() {
  return (
    <div className="about-top1">
      {/* <div className="container-fluid  about-landscape">
        <div className="main-img landscape-mob col-lg-12 col-md-12 col-sm-12">
          <img src="about-img/Artboard 5.jpg" alt="banner of about"/>
<div className="about_banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">About Us</h1>
                  <p>"Passionate innovators dedicated to delivering exceptional solutions and creating lasting impact."</p>
                  <div className="about-box">
                    <span className="mb-0 box-home">Home</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">About</span>
                  </div>
                </div>
        </div>
      </div> */}

<AboutNewRead/>
<AboutCard/>
<WhyChooseNew/>
<Recognised/>
<ClientSpeackData/>
<Gfooter/>
    </div>
  );
}

export default About;
