import React from "react";
function Form2() {
  return (
    <div className="container box19">
      <h4>Leave a Reply</h4>
      <form>
        <div className="row">
          <div classname="form-check">
            <input
              classname="form-check-input mt-1"
              type="checkbox"
              defaultValue
              id="flexCheckDefault"
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              <small>
                {" "}
                <span>
                  {" "}
                  save my name,email, and website in this browser for the next
                  time I comment
                </span>
              </small>
            </label>
            <br />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <input
              type="text"
              className="form-control"
              placeholder="Full Name*"
              name="full name"
            />
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <input
              type="text"
              className="form-control"
              placeholder="Email*"
              name="pswd"
            />
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <input
              type="text"
              className="form-control"
              placeholder="Website*"
              name="web"
            />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <textarea
                className=" form-control "
                id="message"
                rows={2}
                cols={40}
                placeholder="Comment*"
                defaultValue={""}
              />
            </div>
            
            <button type="button" className=" form-button">
            
              Post Comment
            </button>{" "}
          </div>
        </div>
      </form>
    </div>
  );
}
export default Form2;
