import React from "react";
import "./service.css";
import MobileSolutions from "./MobileSolutions";
import Testing from "./Testing";
import Esolution from "./Esolution";
import BussinessIntelligence from "./BussinessIntelligence";
import DataScience from "./DataScience";
import FocusPage from "./FocusPage";
import DataEntry from "./DataEntry";
import Gfooter from "../Component/Gfooter";

function Service() {
  return (
      <div>
        {/* <div className="container-fluid service">
        <div className="service-image col-lg-12 col-md-12 col-sm-12">
          <img src="about-img/services page.jpg" alt="Service We Offer"/>
          <div className="service-banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Services</h1>
                  <p>"Transform your business with our tailored solutions designed to drive growth and efficiency."</p>
                  <div className="service-box">
                    <span className="mb-0 box-home">Home</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Services</span>
                  </div>
                </div>
        </div>
        </div> */}
        <FocusPage/>
        <MobileSolutions/>
        <Testing/>
        <Esolution/>
        <BussinessIntelligence/>  
        <DataScience/>
        <DataEntry/>
        <Gfooter/>
      </div>
  );
}

export default Service;
