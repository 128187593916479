import React from 'react'
import Slider from './Slider';
import OurNumber from './OurNumber';
import Client from './Client';
import Video from './Video';
import ProductNew from './ProductNew';
import AboutNew from './AboutNew';
import AboutCard from '../About/AboutCard';
import Gfooter from './Gfooter';
import Speak from './Speak';
import Whyus from '../About/Whyus';
import Navbar from './Navbar';





function Home() {
    return ( 
        <>
        <Navbar/>
        <Video/>
        <Slider/>
        <ProductNew/>
        <AboutNew/>
        <AboutCard/>
        <Whyus/>
        <OurNumber/>
        {/* <Speak/> */}
        <Client/>
        <Gfooter/>
        </>
     );
}

export default Home;