import React from "react";
import "./Product.css";
import { Link } from "react-router-dom";



function Product() {
  return (  
    <div className="product-complete">
      {/* <div className="container-fluid products-back">
        <div className="productback-image  col-lg-12 col-md-12 col-sm-12">
          <img src="about-img/pages page.jpg" alt="caseimage3" />
          <div className="product-banner aos-init aos-animate" data-aos="fade-up">
                  <h1 className="text-white">Products</h1>
                  <p>"Discover innovation with our cutting-edge product, designed to elevate your everyday experience."</p>
                  <div className="product-box">
                    <span className="mb-0 box-home">Home</span>
                    <span className="mb-0 arrow"><i className="fa-solid fa-arrow-right"></i></span>
                    <span className="mb-0 box_span">Products</span>
                  </div>
                </div>
        </div>
      </div> */}
      <div className="product-data-top">
        <div className=" container" id="product-info-section1">
          <h2 className="product-head1">
            <strong data-aos="zoom-in" data-aos-delay={40}>Global Port Management System</strong>
          </h2>
        </div>

        <div className="container product para-data">
          <div className="product-info col-lg-12 col-md-12 col-sm-12">
            <img src="project-pic/portmanagement.jpg" alt=" portmanagement pic"/>

            <div className="container product-content col-lg-6 col-md-12 col-sm-12">
              <h2 className="product-heading">
                <strong>Global Port Management System</strong>
              </h2>
              <p className="para" data-aos="flip-left" data-aos-delay={100}>
                Managing terminal operations in the maritime industry demands
                efficiency and cost-effectiveness amidst complex logistics and
                high operational expenses. Introducing our Global Port
                Management System (GPMS), a cutting-edge solution tailored to
                streamline and enhance terminal management processes.
              </p>
              <Link to="/read">
                <button className="product-button" type="submit" data-aos="zoom-in" data-aos-delay={150}>
                  Read More
                </button>
              </Link>
            </div>
            <Link to="/contact-us">
                <button className="contact-button">
                  Request For Demo
                </button>
              </Link>
          </div>
        </div>

        <div className=" container" id="product-info-section2">
          <h2 className="producttop-heading">
            <strong className="ifile-head" data-aos="zoom-in" data-aos-delay={60} >Global iFile Management System</strong>
          </h2>
        </div>

        <div className="container product">
          <div className="product-info2 col-lg-12 col-md-12 col-sm-12">
            <img src="product-img/efile.jpg" alt=" yard pic" />

            <div className="container product-content2 col-lg-6 col-md-12 col-sm-12">
              <h2 className="product-heading">
                <strong>Streamlining Document Management</strong>
              </h2>
              <p className="para2" data-aos="flip-right" data-aos-delay={100}>
                Efficient document management is critical for organizational
                productivity and compliance. Introducing Global iFile, a
                comprehensive document management system designed to streamline
                document handling and enhance operational efficiency.
              </p>
              <Link to="/read">
                <button className="product-button" type="submit" data-aos="zoom-in" data-aos-delay={140}>
                  Read More
                </button>
              </Link>
            </div>
            <Link to="/contact-us">
                <button className="contact-button2">
                  Request For Demo
                </button>
              </Link>
          </div>
        </div>

        <div className="container iparking-top" id="product-info-section3">
          <h2 className="product-head1">
            <strong data-aos="zoom-in" data-aos-delay={80} >Global iParking Management System</strong>
          </h2>
        </div>

        <div className="container product para-data iparking-top2">
          <div className="product-info col-lg-12 col-md-12 col-sm-12">
            <img src="project-pic/iparkingproduct.jpg" alt=" yard pic" />

            <div className="container product-content col-lg-6 col-md-12 col-sm-12">
              <h2 className="product-heading">
                <strong>Redefining Parking Management</strong>
              </h2>
              <p className="para" data-aos="flip-left" data-aos-delay={100}>
                Parking management in urban areas and commercial centers
                presents significant challenges in terms of efficiency and user
                experience. Introducing Global iParking, an innovative solution
                designed to revolutionize the way parking facilities are
                managed.
              </p>
              <Link to="/read">
                <button className="product-button" type="submit" data-aos="zoom-in" data-aos-delay={140}>
                  Read More
                </button>
              </Link>
            </div>
            <Link to="/contact-us">
                <button className="contact-button">
                  Request For Demo
                </button>
              </Link>
          </div>
        </div>

        <div className=" container" id="product-info-section4">
          <h2 className="producttop-heading">
            <strong className="ifile-head2" data-aos="zoom-in" data-aos-delay={100} >Global Toll Plaza Management System</strong>
          </h2>
        </div>

        <div className="container product toll-top">
          <div className="product-info2 col-lg-12 col-md-12 col-sm-12">
            <img src="project-pic/tollplazaimg.jpg" alt=" yard pic" />

            <div className="container product-content2 col-lg-6 col-md-12 col-sm-12">
              <h2 className="product-heading">
                <strong>Enhancing Efficiency in Toll Collection</strong>
              </h2>
              <p className="para2" data-aos="flip-right" data-aos-delay={100}>
                Efficient toll plaza management is crucial for ensuring smooth
                traffic flow and accurate revenue collection on highways.
                Introducing our Global Toll Plaza Management System (GTPMS), a
                state-of-the-art solution designed to optimize toll collection
                operations.
              </p>
              <Link to="/read">
                <button className="product-button pro-btn" type="submit" data-aos="zoom-in" data-aos-delay={140}>
                  Read More
                </button>
              </Link>
            </div>
            <Link to="/contact-us">
                <button className="contact-button2">
                  Request For Demo
                </button>
              </Link>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Product;
