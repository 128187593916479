import React from "react";
import { Link } from "react-router-dom";
import Gfooter from "../Component/Gfooter";

function Project2() {
  return (
    <>
       <div className="container-fluid project-bg ">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <img src="career-img/project2bg.jpg" alt="  QA Automation" />
        </div>
      </div>
  
      <div className="container-fluid job-details">
        <div className="row">
          <div className="col-lg-12 col-md-10 col-sm-12">
              <h1 className="job-project-heading"><strong>QA Automation</strong></h1>
          </div>
        </div>
        <div className="max-w-1200 mx-auto">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-7 col-sm-12 pr-50 mb-5">
              <div className="job-description-container ql-editor">
                <h2>Requirements:</h2>
                <p>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    A minimum one (1) years of web automation with Selenium,
                    Appium, UFT, VBscript.
                    <p className="set-data">
                      <ul>
                        <li>
                          * A minimum two (2) years API test automation
                          required.
                        </li>
                        <li>
                          * Experience in the following languages; Java,
                          Cucumber, FEST.
                        </li>
                        <li>
                          * Experience with integration tools like Jenkins,
                          Appitools is preferred.
                        </li>
                        <li>
                          * Experience using SQL with regard to writing and
                          understanding queries and procedures.
                        </li>
                        <li>
                          * Ability to write solid documentation that supports
                          the testing automation effort.
                        </li>
                        <li>
                          * Experience in creating, organizing and implementing
                          test case automation through a common tool or set of
                          tools.
                        </li>
                        <li>
                          * Ability to diagnose potential problems and solutions
                          during the testing process.
                        </li>
                        <li>
                          * Excellent communication and interpersonal skills.
                        </li>
                        <li>
                          * Desired: Previous work experience with Cucumber
                          framework.
                        </li>
                        <li>* Desired: Previous work experience with JIRA</li>
                      </ul>
                    </p>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="sticky-job">
                <div className="btn-container col-md-9 col-sm-12">
                  <button className="btn btn-info" id="apply-job">
                    <Link to="/form">
                      Apply for this job{" "}
                      <span className="arrow-icon">
                        <i className="fa-solid fa-arrow-right-long" />
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   <Gfooter/>
    </>
  );
}

export default Project2;
