import React from "react";

function Client() {
  return (
    <div className="container-fluid client-title" >
      
      <div className="container">
        <h1 className="heading-about" data-aos="fade-up" data-aos-delay={20}>
          <strong>Our Clients</strong>
        </h1>
      </div>
   
      <div className="container-fluid icon">
        <div className="marquee hover-pause">
          <div className="marquee-content">
            <li>
              <img
                src="img/moscord1.png"
                alt="Moscord"
                className="img-contain"
                style={{ height: 100 }}
              />
            </li>
            <li>
              <img
                src="img/lackthorn1.png"
                alt="Lackthorn"
                className="img-contain"
                style={{height: 70, width: 290 }}
              />
            </li>
            <li>
              <img
              src="img/smart1.png"
                alt="Smart Village KADOLI"
                className="img-contain"
                style={{ marginTop: 10 }}
              />
            </li>
            <li>
              <img
                src="img/unnati.jpg"
                alt="Unnati Motors"
                className="img-contain"
              />
            </li>
            <li>
              <img
                src="img/smile.jpeg"
                alt="Smile Craft"
                className="img-contain"
              />
            </li>
            <li>
              <img
                src="img/Grupo1.png"
                alt="Group Murchison"
                className="img-contain"
                style={{ height: 55}}
              />
            </li>
            <li>
              <img
                src="img/wilson1.png"
                alt="Wilson, Sons"
                className="img-contain"
              />
            </li>
          </div>


          <div className="marquee-content">
            <li>
              <img
                src="img/moscord1.png"
                alt="Moscord"
                className="img-contain"
                style={{ height: 100 }}
              />
            </li>
            <li>
              <img
                src="img/lackthorn1.png"
                alt="Lackthorn"
                className="img-contain"
                style={{height: 70, width: 290 }}
              />
            </li>
            <li>
              <img
              src="img/smart1.png"
                alt="Smart Village KADOLI"
                className="img-contain"
                style={{ marginTop: 10 }}
              />
            </li>
            <li>
              <img
                src="img/unnati.jpg"
                alt="Unnati Motors"
                className="img-contain"
              />
            </li>
            <li>
              <img
                src="img/smile.jpeg"
                alt="Smile Craft"
                className="img-contain"
              />
            </li>
            <li>
              <img
                src="img/Grupo1.png"
                alt="Group Murchison"
                className="img-contain"
                style={{ height: 55}}
              />
            </li>
            <li>
              <img
                src="img/wilson1.png"
                alt="Wilson, Sons"
                className="img-contain"
              />
            </li>
          </div>
        </div>
      </div>
      </div>
  );
}

export default Client;
